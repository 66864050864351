import axios from 'axios';
// axios.defaults.timeout = 5000;
// axios.defaults.baseURL = 'http://apitest.caihongyixianggou.com/YxgService';
import state from '../../store/state'


//http request 拦截器
axios.interceptors.request.use(
  config => {
    config.data = JSON.stringify(config.data);
    config.headers = {
      'Content-Type': 'application/json',
    }
    return config;
  },
  error => {
    return Promise.reject(err);
  }
);

/**
 * 封装post请求,公共方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function commonPost(data = {}) {
  return new Promise((resolve, reject) => {
    data.nbr = state.userInfo.nbr || "0"
    data.token = state.token || "0"
    axios.post('/api/customer/ajax', { mos: data })
      .then(response => {
        let data = response.data
        if (data.code == '200') {
          let resp = data.object.resp
          if (resp.resp_code == '0' && resp.resp_result == '0') {
            resolve(resp);
          } else {
            let err = {
              resp: resp,
              message: resp.resp_msg
            }
            reject(err)
          }
        } else {
          if ((data.code == '404' || data.code == '456' || data.code == '458' || data.code == '445') && state.userInfo.nbr) {
            state.userInfo = {}
            state.token = ''
            state.isKick = '1'
            localStorage.removeItem('userInfo');
            localStorage.removeItem('token');
            this.$router.push("/login");
            return false
          }
          let err = {
            message: data.message
          }
          reject(err)
        }
      }, error => {
        let err = {
          message: '您现在的网络不稳定，请稍后再试'
        }
        reject(err)
      })
  })
}

/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function fetch(url, params = {}) {
  return new Promise((resolve, reject) => {
    axios.get(url, {
      params: params
    }).then(response => {
      resolve(response.data);
    })
      .catch(err => {
        reject(err)
      })
  })
}


/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.post(url, data)
      .then(response => {
        resolve(response.data);
      }, err => {
        reject(err)
      })
  })
}

/**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function patch(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.patch(url, data)
      .then(response => {
        resolve(response.data);
      }, err => {
        reject(err)
      })
  })
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function put(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.put(url, data)
      .then(response => {
        resolve(response.data);
      }, err => {
        reject(err)
      })
  })
}