<template>
  <div class="management">
    <div style="height: 64px; width: 100%"></div>
    <div class="managementBox">
      <div class="managementTopBox">
        <div class="managementTitle">资金数据统计</div>
        <div class="managementCapital">
          <div class="capitalBox">
            <div>{{info.pending_amount||"0.00"}}</div>
            <div>待结算佣金</div>
          </div>
          <div class="capitalBox">
            <div>{{info.brokerage||"0.00"}}</div>
            <div>已结算佣金</div>
          </div>
          <div class="capitalBox">
            <div>{{info.equity||"0.00"}}</div>
            <div>股权账户</div>
          </div>
        </div>
      </div>
      
      <div class="detailedBox">
        <div class="commissionCapital" @click="goChronicle('/commStatistics')">佣金订单明细</div>
        <div class="commissionCapital" @click="goChronicle('/userRecord')">提现记录</div>
      </div>

      <div class="withdrawalBox">
        <div class="withdrawalTitle">提现</div>
        <div class="withdrawalMess">
          <div class="withdrawalName">提现金额数:</div>
          <a-input
            type="Number"
            v-model="ZFBamount"
            placeholder="请输入提现金额数"
          />
        </div>
        <div class="withdrawalMess">
          <div class="withdrawalName">支付宝名称:</div>
          <a-input v-model="ZFBname" placeholder="请输入支付宝名称" />
        </div>
        <div class="withdrawalMess">
          <div class="withdrawalName">支付宝账号:</div>
          <a-input v-model="ZFBaccount" placeholder="请输入支付宝账号" />
        </div>
        <div class="withdrawalMess">
          <a-button type="primary" @click="submitForm"
            >申请提现</a-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
 
<script>
import { message } from 'ant-design-vue';
import {mapGetters} from 'vuex';
export default {
  name: "Management",
  components: {},
  data() {
    return {
      ZFBamount: null,
      ZFBname: "",
      ZFBaccount: "",
      info:{},
    };
  },
  computed:{
    ...mapGetters(["userInfo"])
  },
  watch: {
    ZFBamount(newValue, oldValue) {
      let nValue = newValue;
      if(nValue>999999999){
        this.ZFBamount = oldValue;
        return
      }
      if (nValue.indexOf(".") == 0) {
        nValue = "0" + nValue;
      }
      nValue = nValue.replace(/[^\d.]/g, "");
      nValue = nValue.replace(/\.{2,}/g, "");
      nValue = nValue.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      nValue = nValue.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3");
      if (nValue.indexOf(".") < 0 && nValue != "") {
        nValue = parseFloat(nValue);
      }
      this.ZFBamount = nValue.toString();
    },
    ZFBname(newValue, oldValue) {},
    ZFBaccount(newValue, oldValue) {},
  },

  methods: {
    goChronicle(url) {
      this.$router.push({
        path: url,
      });
    },

    submitForm() {
      let that = this;
      if(this.ZFBamount == "" || this.ZFBamount <= 0){
        message.success('请填写正确的提现金额！');
        return;
      }
      if(this.ZFBname == ""){
        message.success('请填写正确的支付宝姓名！');
        return;
      }
      if(this.ZFBaccount == ""){
        message.success('请填写正确的支付宝账号！');
        return;
      }

      this.$commonPost({
        jydm: "flow_60001",
        flow_60001: {
          user_nbr:that.userInfo.nbr,
          amount:that.ZFBamount,
          type:1,
          cashacc:that.ZFBaccount,
          name:that.ZFBname,
        }, 
      }).then(resp=>{
       if(resp.resp_code==0 && resp.resp_result==0){
        that.$message.success("提现申请"+resp.resp_msg);
        that.ZFBamount = "" 
        that.ZFBname = ""
        that.ZFBaccount = ""
        that.reqGetData();
       }else{
        that.$message.success(resp.resp_msg)
       }
      })
      .catch(err=>{
        that.$message.error(err.message)
      })
    },

    // TODO:个人信息接口
    reqGetData() {
      let that = this;
      this.$commonPost({
        jydm: "flow_10003",
        flow_10003: {
          user_nbr: that.userInfo.nbr,
        },
      })
        .then((resp) => {
          if (resp) {
            this.info = resp;
          }
        })
        .catch((err) => {
         message.error(err.message)
        });
    },
  },
  mounted() {},
  created() {
    this.reqGetData();
  },
};
</script>
<style lang="less" scoped>
.management {
  width: 100%;
  margin: 0 auto;
  background: #f0f0f0;
  padding-bottom: 20px;
}
.managementBox {
  width: 1190px;
  margin: 0 auto;
  .managementTopBox {
    height: 194px;
    background: #ffffff;
    .managementTitle {
      height: 58px;
      line-height: 58px;
      margin-left: 20px;
      font-size: 16px;
      color: #444444;
    }
    .managementCapital {
      display: flex;
      height: 136px;
      .capitalBox {
        font-size: 16px;
        color: #595961;
        width: 396px;
        height: 136px;
        padding: 38px 0 38px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        :nth-child(1) {
          margin-bottom: 8px;
        }
      }
    }
  }
  .detailedBox {
    width: 100%;
    font-size: 16px;
    color: #5684ff;
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    justify-content: space-between;
    .commissionCapital {
      cursor: pointer;
      background: #ffffff;
      width: 590px;
      height: 60px;
      text-align: center;
      line-height: 60px;
    }
  }

  .withdrawalBox {
    width: 100%;
    background: #ffffff;
    padding: 20px 0 20px 20px;
    .withdrawalTitle {
      font-size: 16px;
      color: #444444;
      margin-bottom: 20px;
    }
    .withdrawalMess {
      width: 400px;
      font-size: 12px;
      color: #444444;
      display: flex;
      margin-top: 10px;
      margin-bottom: 10px;
      .withdrawalName {
        width: 100px;
        line-height: 32px;
      }
    }
  }
}
</style>
<style scoped>
</style>
