<template>
  <div class="selectPayment">
    <div class="selectPaymentBox">
      <div class="orderPay">
        <div class="orderPayTitle">订单信息</div>
        <div class="orderPayMess">
          <p>商品总金额：<i>￥{{dataList.amount}}</i></p>
          <p>运费总金额：<i>￥{{dataList.postage}}</i></p>
          <p>订单总金额：<i>￥{{dataList.sum_amount}}</i></p>
          <p>实付总金额：<i>￥{{dataList.final_state == "4" || dataList.state == "1" || dataList.state == "4" ? "0":dataList.sum_amount}}</i></p>
          <p>订单编号：{{dataList.show_nbr}}</p>
          <p>创建时间：{{dataList.create_time}}</p>
        </div>
      </div>
      <div class="orderChoicePayBox">
        <div class="orderChoicePay">选择支付方式</div>
        <div class="orderChoicePayMess">
          <a-button type="danger" @click="goPay('1')" size="large">微信支付</a-button>
          <a-button type="danger" @click="goPay('2')" size="large">支付宝支付</a-button>
          <a-button type="danger" @click="goPay('3')" size="large">上传银行支付凭证</a-button>
        </div>
        <div class="bankMess" v-if="paymentLabel == '3'">
          <p>银行开户行：{{information2}}</p>
          <p>银行卡账号：{{information1}}</p>
          <p>银行卡姓名：{{information3}}</p>
        </div>
      </div>

      <!-- 银行卡支付 -->
      <div class="voucherBox" v-if="paymentLabel == '3'">
        <div class="voucherText">上传银行卡支付凭证</div>
        <div class="voucherBtn">
          <div style="width: 120px;font-size: 14px; color: #FF3B30;">*上传照片</div>
          <a-upload
            name="file"
            list-type="picture-card"
            class="avatar-uploader"
            :show-upload-list="false"
            :before-upload="beforeRead"
            :customRequest="upload"
          >
            <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
            <div v-else>
              <a-icon :type="loading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">上传凭证</div>
            </div>
          </a-upload>
        </div>
         <a-button style="margin-left: 16px;" type="primary" v-if="imageUrl==''" :disabled="disabled">确认提交审核</a-button>
         <a-button style="margin-left: 16px;" type="primary" v-else @click="subReviewPlace">确认提交审核</a-button>
      </div>

      <!-- 微信支付码 -->
      <div class="voucherBox" v-if="paymentLabel == '1' && weChatImg != ''" >
        <div class="voucherText">微信支付码</div>
        <div class="voucherBtn" style="display:flex;flex-direction: column;align-items:center;">
          <div style="font-size: 20px; color: #333; font-weight:bold">等待支付</div>
          <img style="width: 200px;" :src="weChatImg" alt="" srcset="">
          <div style="font-size: 20px; color: #333; font-weight:bold">打开微信扫描二维码</div>
        </div>
      </div>

      <!-- 支付宝支付 -->
      <div class="voucherBox" v-if="paymentLabel == '2'">
        <div class="voucherText">支付宝支付码</div>
        <iframe style="height:300px; width:1000px;" :srcdoc='url'></iframe>
      </div>

    </div>
  </div>
</template>
<script>
import OSS from "ali-oss";
import { dataURLtoFile, photoCompress } from "../../assets/js/tool";
import { mapGetters } from "vuex"
export default {
  data() {
    return {
      loading: false,
      imageUrl: '',
      paymentLabel:'',
      disabled:true,
      orderNbr:'',
      weChatImg:'',
      timing:null,
      dataList:{},
      sum:0,
      url:'',
      flowingWater:'',
      information1:'',
      information2:'',
      information3:'',
    };
  },
  computed:{
    ...mapGetters(['userInfo'])
  },
  methods: {
    // 银行卡支付下单
    subReviewPlace(){
      let that = this;
      that.$post("/api/pay/readyPay",{
        userNbr: that.userInfo.nbr,
        orderNbr: that.orderNbr,
        paymentMethod: "OFFLINEBANK",
      }).then(resps=>{
        if(resps.code == 200){
          let {resp} = resps.object
          if(resp.resp_code == 0 && resp.resp_result == 0){
            // 支付流水号
            that.flowingWater = resp.transSerialNbr;
            if(that.flowingWater){
              that.subReview();
            }
          }else{
            that.$message.error(resp.resp_msg);
          }
        }else{
          that.$message.error(resps.message);
        }
      }).catch(err=>{
        that.$message.error(err.message);
      })
    },

    // 银行卡实际支付
    subReview(){
      let that = this;
      this.$commonPost({
        jydm: "flow_60049",
        flow_60049: {
          trans_serial_nbr:that.flowingWater,
          certificate:that.imageUrl,
        },}).then(resps=>{
          if(resps.resp_code == 0 && resps.resp_result == 0){
            that.$message.success(resps.resp_msg);
            that.$router.push({
              path:'/paymentStatus',
              query: {orderNbr: that.dataList.order_nbr,type:'3'},
            });
          }else{
            that.$message.error(resps.resp_msg);
          }

      }).catch(err=>{
        that.$message.error(err.message);
      })
    },

    beforeRead(file) {
      if (file.type == "image/jpeg" ||file.type == "image/jpg" ||file.type == "image/png") {
        return true;
      } else {
        this.$message.error('请上传图片格式为jpg，jpeg，png格式！!');
        return false;
      }
    },

    upload(file) {
      const that = this;
      let quality = Number((1048576 / file.file.size).toFixed(1)); //压缩到1m以内  算出压缩比例
      photoCompress(file.file, callback, quality); //将图片进行压缩
        function callback(res) {
          let newFile = dataURLtoFile(res, file.file.name);
          let url = that.uploadPath("images/identity", newFile);
          that.$post("/api/customer/getOSSToken", {
            userNbr: that.userInfo.nbr,
            token: "123456",
          }).then((data) => {
            let OSSToken = data.object.resp;
            if (OSSToken.resp_code == 0 && OSSToken.resp_result == 0) {
              let client = new OSS({
                endpoint: OSSToken.endpoint,
                bucket: OSSToken.bucketName,
                accessKeyId: OSSToken.accessKeyId,
                accessKeySecret: OSSToken.accessKeySecret,
                stsToken: OSSToken.securityToken,
              });
              client.options.endpoint.protocol = "https:";
              client.put(url, newFile).then((data) => {
                  let imgUrl = that.aliImgPath + "/" + data.name;
                  console.log(that.aliImgPath);
                  console.log(imgUrl);
                  that.imageUrl = imgUrl;
                  that.$forceUpdate();
                  that.$message.success('上传成功');
                }).catch((err) => {
                  that.$message.error('上传失败');
                });
              } else {
              that.$message.error(OSSToken.resp_msg);
            }
          }).catch((err) => {that.$message.error(err.message);});
        }
    },

    uploadPath(path, file) {
      return `${path}/${this.userInfo.nbr}-${new Date().getTime()}.${
        file.type.split("/")[1]
      }`;
    },

    // 去支付
    goPay(type){
      let that = this;
      that.paymentLabel = type;
      if(type == "1"){
        // 微信
        that.weChatPay();
      }else if(type == "2"){
        // 支付宝
        that.aliPay();
      }
    },

    // 支付宝支付
    aliPay(){
      let that = this;
      that.$post("/api/pay/readyPay",{
        userNbr: that.userInfo.nbr,
        orderNbr: that.orderNbr,
        paymentMethod: "ALIPAYPAGE",
      }).then(resps=>{
        if(resps.code == 200){
          let {resp} = resps.object
          if(resp.resp_code == 0 && resp.resp_result == 0){
            let res = resp.resp;
            if(res.orderInfo){
              that.url = res.orderInfo;
              setTimeout(() => {
                that.checkWeChatSuccess();
              }, 1000);
            }
          }else{
            that.$message.error(resp.resp_msg);
          }
        }else{
          that.$message.error(resps.message);
        }
      }).catch(err=>{
        that.$message.error(err.message);
      })
    },

    // 微信支付
    weChatPay(){
      let that = this;
      that.$post("/api/pay/readyPay",{
        userNbr: that.userInfo.nbr,
        orderNbr: that.orderNbr,
        paymentMethod: "WECHATNATIVEPAY",
      }).then(resps=>{
        if(resps.code == 200){
          let {resp} = resps.object
          if(resp.resp_code == 0 && resp.resp_result == 0){
            let res = resp.resp;
            if(res.codeImageUrl){
              that.weChatImg = res.codeImageUrl;
              setTimeout(() => {
                that.checkWeChatSuccess();
              }, 1000);
            }
          }else{
            that.$message.error(resp.resp_msg);
          }
        }else{
          that.$message.error(resps.message);
        }
      }).catch(err=>{
        that.$message.error(err.message);
      })
    },

    // 查询支付是否成功
    checkWeChatSuccess() {
      let that = this;
      that.$post("/api/pay/order/checkPaySuccess", {
        orderNbr: this.orderNbr,
      }).then((data) => {
        if (data.code == 200) {
          let resp = data.object.resp;
          if (resp.resp_code == 0) {
            if (resp.resp_result == 0) {
              that.$message.success("支付成功");
              clearTimeout(that.timing);

                that.$router.replace({
                  path: "/PaymentStatus",
                  query: {orderNbr: that.dataList.order_nbr},
                });

            } else if (resp.resp_result == 2) {
              that.timing = setTimeout(() => {
                that.checkWeChatSuccess();
              }, 1000);
            } else {
              that.$router.go(-1);
              that.$message.error("支付失败");
              clearTimeout(that.timing);
            }
          } else {
            that.$router.go(-1);
            that.$message.error(resp.resp_msg);
            clearTimeout(that.timing);
          }
        } else {
          that.$router.go(-1);
          that.$message.error(data.message);
          clearTimeout(that.timing);
        }
      }).catch((err) => {
        that.$router.go(-1); 
        that.$message.error(err.message);
        clearTimeout(that.timing);
      });
      that.sum++;
      if(that.sum>=60){
        for(let i = 0;i<that.timing;i++){
          clearTimeout(i);
        }
        that.sum = 0;
      }
    },

    // 查询商品信息
    reqSubDetailsList(){
      let that = this;
      this.$commonPost({
        jydm: "flow_30025",
        flow_30025: {
          order_nbr:that.orderNbr,
          search:'',
          page_index:0,
          num_per_page:10,
        },
      }).then(resp=>{
       if(resp.resp_code==0 && resp.resp_result==0){
        that.dataList = {};
        that.dataList = resp;
       }else{
        that.$message.error(resp.resp_msg)
       }
      })
      .catch(err=>{
        that.$message.error(err.message)
      })
    },

    // 提现银行卡账户信息
    reqAccountInformation(){
      let that = this;
      this.$commonPost({
        jydm: "flow_10060",
        flow_10060: {
          scene:"",
        },
      }).then(resp=>{
       if(resp.resp_code==0 && resp.resp_result==0){
        console.log(resp);
        let arr = resp.config;
        let obj = {};
        arr.forEach((e) => {
          obj[e.nbr] = e.value;
        });
        that.information1=obj[10034]
        that.information2=obj[10035]
        that.information3=obj[10036]
       }else{
        that.$message.error(resp.resp_msg)
       }
      })
      .catch(err=>{
        that.$message.error(err.message)
      })
    },
  },
  activated(){
    if(this.$route.meta.keepAlive == true){
      this.orderNbr = this.$route.query.orderNbr;
      let orderNbr1 = window.localStorage.getItem('orderNbr');
      if(this.orderNbr != orderNbr1 && this.orderNbr != undefined){
        window.localStorage.setItem('orderNbr',this.orderNbr);
        this.orderNbr = window.localStorage.getItem('orderNbr');
      }else{
        this.orderNbr = window.localStorage.getItem('orderNbr');
      }
      this.reqSubDetailsList();
      this.reqAccountInformation();
    }
    // this.orderNbr = this.$route.query.orderNbr;
    // this.reqSubDetailsList();
    // this.reqAccountInformation();
  },
  created(){},
  mounted(){
    this.orderNbr = this.$route.query.orderNbr;
    this.reqSubDetailsList();
    this.reqAccountInformation();
  },
};
</script>
<style lang="less" scoped>
.selectPayment {
  width: 100%;
  margin: 0 auto;
  background: #f0f0f0;
  padding-bottom: 20px;
}
.selectPaymentBox {
  padding-top: 64px;
  width: 1190px;
  margin: 0 auto;
}
.orderPay {
  background: #fff;
  padding: 0 16px 10px 16px;
  .orderPayTitle {
    font-weight: bold;
    line-height: 52px;
    font-size: 20px;
    color: #424242;
    border-bottom: 1px #E8E8E8 solid;
    margin-bottom: 10px;
  }
  .orderPayMess{
    p{
      font-size: 14px;
      color: #575757;
      margin-bottom: 10px;
      i{
        font-size: 14px;
        color: #FF2000;
      }
    }
  }
}
.orderChoicePayBox{
  background: #fff;
  padding: 0 16px 10px 16px;
  margin-top: 10px;
  .orderChoicePay{
    font-weight: bold;
    line-height: 52px;
    font-size: 20px;
    color: #424242;
    border-bottom: 1px #E8E8E8 solid;
    margin-bottom: 10px;
  }
  .orderChoicePayMess{
    display: flex;
  }
  .bankMess{
    margin-top: 20px;
    p{
      font-size: 14px;
      color: #333333;
      font-weight:bold;
    }
  }
}
.voucherBox{
  background: #fff;
  padding: 0px 0px 10px 0px;
  .voucherText{
    padding: 0 0px 0px 16px;
    font-size: 20px;
    color: #333333;
    background: #F0F0F0;
    line-height: 70px;
    font-weight:bold;
  }
  .voucherBtn{
    padding: 16px 16px 10px 16px;
    display: flex;
    width: 100%;
  }
}
</style>
<style scoped>
.ant-btn-danger{
 margin-right: 10px;
}
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
