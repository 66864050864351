<template>
  <div class="orderCommodity">
    <div style="height: 64px; width: 100%"></div>
    <div class="orderCommodityBox">
      <div class="orderCommodityTop">
        <div class="orderTitle">订单信息</div>
        <p>商品总金额：<i>￥{{dataList.amount || "0"}}</i></p>
        <p>运费总金额：<i>￥{{dataList.postage || "0"}}</i></p>
        <p>订单总金额：<i>￥{{dataList.sum_amount || "0"}}</i></p>
        <p>实付总金额：<i>￥{{dataList.final_state == "4" || dataList.state == "1" || dataList.state == "4" ? "0":dataList.sum_amount}}</i></p>
        <p>订单编号：{{dataList.show_nbr}}</p>
        <p>创建时间：{{dataList.create_time || '暂无'}}</p>
        <p>支付时间：{{dataList.update_time || '暂无'}}</p>

        <p>支付状态：{{dataList.final_state == "4" ? "待支付" : dataList.final_state == "6" ? "已支付" : dataList.final_state == 10 ? '关闭':''}}
          <i v-if="dataList.state == '4'" style="color:#0094FF;cursor:pointer;" @click="goPay(dataList.order_nbr)">去支付</i>
        </p>

        <p>订单支付方式：{{dataList.money_pay_type == "ALIPAYPAGE" ? "支付宝":
          dataList.money_pay_type == "WECHATPAY" ? "微信":
          dataList.money_pay_type == "OFFLINEBANK" ? "银行卡":"暂无"}}
        </p>
          
        <p v-if="dataList.money_pay_type == 'OFFLINEBANK'">银行卡审核状态：{{dataList.state == "1" ? "待审核" : dataList.state == "3"? "已审核" : dataList.state == "4" ? "审核失败" : "暂无" }}</p>
        <div v-if="dataList.money_pay_type == 'OFFLINEBANK'" class="voucher"><p>银行卡支付凭证：</p>
          <img @click="viewPicture(dataList.certificate)" v-if="dataList.certificate" :src="dataList.certificate" alt="">
          <span style="margin-bottom: 8px; margin-top: 8px;" v-else>暂无</span>
        </div>
    </div>

      <div class="resultOrderBox">
      <!-- 时间选择 -->
      <div class="queryBox">
        <div class="inputQueryBox">
          <div class="conditionQuery">条件查询：<a-input style="width:172px" v-model="queryMess" placeholder="请输入商品名称" /></div>
        </div>

        <!-- 确认按钮 -->
        <div class="orderStatus">
          <a-button type="primary" @click="searchList" class="bePaid">搜索</a-button>
          <a-button @click="resetList">重置</a-button>
        </div>
      </div>

      <!-- 表格 -->
      <div class="formBox">
        <table>
          <tr>
            <th style="width: 514px;">商品信息</th>
            <th style="width: 252px;">购买数量</th>
            <th style="width: 198px;">商品金额</th>
            <th style="width: 198px;">商品运费</th>
            <th style="width: 198px;">总金额</th>
            <th style="width: 198px;">商户小订单ID</th>
            <th style="width: 198px;">小订单ID</th>
            <th style="width: 198px;">订单状态</th>
            <th style="width: 226px;">操作</th>
          </tr>
          <tr class="myStyle" :class="index % 2 == 0 ? 'myStyle1 myStyle2' : 'myStyle myStyle2'" v-for="(item,index) in list" :key="item.key">
            <td>
              <div class="commMess">
                <img class="commMessImg" :src="item.commodity_first_pic" alt="">
                <div class="commText">
                  <div>{{item.commodity_name || '暂无'}}</div>
                  <div>规格：{{item.property || '暂无'}}</div>
                  <div>编号：{{item.commodity_nbr || '暂无'}}</div>
                </div>
              </div>
            </td>
            <td>{{item.quantity || '暂无'}}</td>
            <td>{{item.sell_price || '暂无'}}</td>
            <td>{{item.postage || '暂无'}}</td>
            <td>{{item.sum_amount || '暂无'}}</td>
            <td>{{item.ext_record_nbr || '暂无'}}</td>
            <td>{{item.commodity_record_nbr || '暂无'}}</td>
            <td>
              {{item.state == 4? '未支付' : item.state == 6 ? '已支付':
              item.state == 7? '已发货':item.state == 8? '已收货':item.state == 10? '关闭':'暂无'}}
            </td>
            <td style="width: 240px;">
              <div class="btn" style="color: 007AFF;">
                <div style=" 
                color:007AFF;"
                @click="goDetail(item.commodity_record_nbr)"
                >查看订单详情</div>
              </div>
            </td>
          </tr>
        </table>
      </div>
      <div style="height: 64px; width: 100%"></div>
      <!-- 分页 -->
      <div class="pagination">
        <a-pagination href="javascript:;" @change="onPaging" :total="totalNum"/>
      </div>
    </div>

      <!-- 弹窗 -->
      <a-modal
      title="图片详情"
      :visible="visible"
      width="700px"
      cancelText="取消"
      okText="确认"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div class="commMess1">
        <img class="commMessImg1" :src="imgSee" alt="">
      </div> 
    </a-modal>

    </div>
  </div>
</template>
<script>
// 默认语言为 en-US，如果你需要设置其他语言，推荐在入口文件全局设置 locale
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import {mapGetters} from "vuex"
import 'moment/locale/zh-cn';
export default {
  data() {
    return {
      scroll:0,
      imgSee:'',
      visible:false,
      dataList:{},
      locale,
      list: [],
      queryMess:"",
      pageIndex:0,
      numPerPage:10,
      totalNum:0,
      orderNbr:'',
    };
  },
  computed:{
    ...mapGetters(["userInfo"])
  },
  methods: {
    goPay(nbr){
      this.$router.push({
        path:'/selectPayment',
        query:{orderNbr : nbr}
      })
    },
    searchList(){
      let that = this;
      that.reqSubDetailsList();
    },

    resetList(){
      let that = this;
      that.queryMess = "";
      that.reqSubDetailsList();
    },

    onPaging(page, pageSize){
      this.pageIndex = page-1;
      this.numPerPage = pageSize;
      this.reqSubDetailsList();
    },

    goDetail(nbr){
      this.$router.push({
        path:'/orderDetail',
        query:{orderNbr : nbr}
      })
    },
    viewPicture(){
      this.visible = true;
    },
    handleOk(){
      this.visible = false;
    },
    handleCancel(){
      this.visible = false;
    },

    reqSubDetailsList(){
      let that = this;
      this.$commonPost({
        jydm: "flow_30025",
        flow_30025: {
          order_nbr:that.orderNbr,
          search:that.queryMess,
          page_index:that.numPerPage * that.pageIndex,
          num_per_page:that.numPerPage,
        },
      }).then(resp=>{
       if(resp.resp_code==0 && resp.resp_result==0){
        that.list = [];
        let arr = [];
        that.dataList = resp;
        that.imgSee = that.dataList.certificate;
        if(resp.commodity){
          if(resp.commodity.length>0) arr = resp.commodity;
          else arr.push(resp.commodity);
        }
        that.list = arr;
        that.totalNum = Number(resp.total_rows);
       }else{
        that.$message.error(resp.resp_msg)
       }
      })
      .catch(err=>{
        that.$message.error(err.message)
      })
    },
  },
  mounted() {
  },
  created() {
    window.scrollTo(0, this.scroll);
    document.body.scrollTop = 0;
    let nbr = this.$route.query.orderNbr;
    window.localStorage.setItem('orderNbr',nbr);
    this.orderNbr = window.localStorage.getItem('orderNbr');
    this.reqSubDetailsList();
  },
  activated(){
  let nbr = this.$route.query.orderNbr;
  window.localStorage.setItem('orderNbr',nbr);
  this.orderNbr = window.localStorage.getItem('orderNbr');
  this.reqSubDetailsList();
  window.scrollTo(0, this.scroll);
  }
};
</script>
<style lang="less" scoped>
.orderCommodity {
  width: 100%;
  margin: 0 auto;
  background: #f0f0f0;
  padding-bottom: 20px;
}
.orderCommodityBox {
  width: 1190px;
  margin: 0 auto;
  .orderCommodityTop {
    width: 100%;
    background: #ffffff;
    padding: 0 16px 16px 16px;
    .orderTitle {
      width: 1158px;
      height: 52px;
      border-bottom: 1px #e8e8e8 solid;
      font-size: 14px;
      color: #424242;
      line-height:52px;
    }
    p{
    margin-bottom: 8px;
    margin-top: 8px;
      i{
        color: red;
      }
    }
    .voucher{
      display: flex;
      img{
        width: 100px;
        height: 100px;
      }
    }
  }
  .queryBox {
  background: #fff;
  padding-left: 32px;
  .inputQueryBox{
    height: 90px;
    display: flex;
    align-items: center;
    border-bottom: 1px #f0f0f0 solid;
    .conditionQuery{
      width: 470px;
    }
  }
  .orderStatus{
      height: 60px;
      display: flex;
      align-items: center;
      .bePaid{
        margin-right: 20px;
      }
  }
}
.resultOrderBox{
  margin-top: 10px;
}
.formBox{
  background: #fff;
  margin-top: 10px;
  font-size: 12px;
  .viewOrder{
    width: 72px;
    height: 15px;
    padding-right: 10px;
    border-right: 1px #D8D8D8 solid;
  }
  table{
    width: 100%;
    text-align: center;
    border: 0px;
    th{
      border-bottom:1px solid #DCDEE3;
      background: #EBECF0;
      padding: 10px;
    }
    td{
       height: 120px;
    }
  }

  .myStyle2:hover{
    background: #F2F2F2;
  }
  .myStyle{
    background: #f8f8f8;
  }
  .myStyle1{
    background: #fff;
  }
}
.btn div{
  font-weight: bold;
  color:#007AFF;
  cursor: pointer;
}
.pagination{
  background: #fff;
  width: 1190px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
}
.commMess{
  width: 100%;
  display: flex;
  justify-content: center;
  .commMessImg{
    width:70px;
    height:70px;
  }
  .commText{
    width: 240px;
    text-align: left;
    margin-left: 10px;
    :nth-child(1){
      font-size: 12px;
      color: #000000;
      margin-bottom: 10px;
    }
    :nth-child(2){
      font-size: 12px;
      color: #999;
    }
    :nth-child(3){
      font-size: 12px;
      color: #999;
    }
  }
}
}
</style>
<style scoped>
  .commMess1{
    width:650px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .commMessImg1 {
    width:650px;
    height:auto;
  }
</style>
