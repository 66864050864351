<template>
  <div id="app">
    <keep-alive>
      <!--使用keep-alive会将页面缓存-->
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import { mapGetters } from "vuex";
export default {
  name: "App",
  computed: {
    ...mapGetters(["userInfo"])
  },
  methods: {
    ...mapMutations({
      senduserInfo: "SENDuserInfo",
    }),
  },
  created() {
    let userInfo = localStorage.getItem('userInfo')
    if(userInfo){
      this.senduserInfo(JSON.parse(userInfo))
    }
  },
  mounted() {
  }
};
</script>

<style>
#app {
  color: #333;
    background: #f0f0f0;
    overflow-x: scroll; 
}

</style>
