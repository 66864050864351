//导出数据更新view

// export const orderType=function(state){
// 	return state.orderType
// };
export const selectaddress = function(state) {
    return state.selectaddress
};
export const payment = function(state) {
    return state.payment
};
export const userInfo = function(state) {
    return state.userInfo
};
export const token = function(state) {
    return state.token
};
