<template>
  <div class="CommodityPoints">
    <div style="height: 64px; width: 100%"></div>
    <div class="commPointsBox">
      <!-- 搜索 -->
      <div class="searchBox">
        <div class="searchFrameBox">
          <div class="searchFrame">
            <input placeholder="请输入商品名称" v-model="queryMess"/>
          </div>
          <div class="searchFrameBtn" @click="reqCategoryName">搜索</div>
        </div>
      </div>

      <!-- 搜索历史 -->
      <div class="searchHistory" v-if="history.length>0">
        <div class="historyBox">
          <div style="width: 70px;flex: 1;">搜索历史：</div>
          <div class="historyFrame">
            <span v-for="(item,index) in history" :key="index" @click="clickSearch(item)">{{item}}</span>
          </div>
        </div>
      </div>
      
      <!-- 筛选 -->
      <div class="firstLevelBox" style="margin-top: -1px;" v-if="list.length>0">
        <div class="firstLevel">
          <div class="fatherBox">商品筛选：</div>
          <div style="display: flex;">
            <div :class="sortIndex == 1 ? 'sortCheck' : 'sortFather'" @click="getSort(1)">默认</div>
            <div :class="sortIndex == 2 ? 'sortCheck' : 'sortFather'" @click="getSort(2)">{{newest}}</div>
            <div :class="sortIndex == 3 ? 'sortCheck' : 'sortFather'" @click="getSort(3)">{{salesVolume}}</div>
            <div :class="sortIndex == 4 ? 'sortCheck' : 'sortFather'" @click="getSort(4)">{{isPrice}}</div>
          </div>
        </div>
      </div>

      <!-- 分类 -->
      <!-- <div class="firstLevelBox">
        <div class="firstLevel">
          <div class="fatherBox">一级分类：</div>
          <div v-for="(item,index) in classify" :key="index">
            <div :class="index == fatherIndex ? 'fatherCheck' : 'father'" @click="getFather(index)">{{item.category_name}}</div>
          </div>
        </div>
      </div>
      
      <div class="firstLevelBox" style="margin-top: -1px;" v-if="moreclList.length>0">
        <div class="firstLevel">
          <div class="fatherBox">二级分类：</div>
          <div v-for="(item,index) in moreclList" :key="index">
            <div :class="index == childrenIndex ? 'childrenCheck' : 'father'" @click="getChildren(index)">{{item.category_name}}</div>
          </div>
        </div>
      </div> -->

      <!-- 商品 -->
      <div class="specialSessionBox" v-if="list.length > 0">
        <div class="specialSession">
          <div class="specialSessionListBox">
            <div class="specialListBox">
              <div class="ListBox" v-for="item in list" :key="item.nbr">
                <img  @click="goDetail(item.nbr)" class="ListBoxImg" :src="item.commodity_logo" alt="" />
                <div class="nameBox">
                  <div class="isName">{{ item.commodity_name }}</div>
                  <div class="bntBox">
                    <div class="isPrice">￥{{ item.sell_price}}</div>
                    <div class="btn" @click="oneClickOption(item.nbr)">一键选品</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 分页 -->
      <div class="pagination">
        <a-pagination href="javascript:;" @change="onPaging" :total="totalNum"/>
      </div>
    </div>
  </div>
</template>
 
<script>
import { message } from 'ant-design-vue';
import { mapGetters } from 'vuex';
export default {
  name: "CommodityPoints",
  computed: {
    ...mapGetters(["userInfo"])
  },
  data() {
    return {
      isPrice:'价格',
      salesVolume:"销量",
      newest:'最新',
      sortIndex:1,
      sortOrder:1,

      totalNum:0,
      queryMess: "",
      list: [],
      pageIndex:0,
      numPerPage:10,

      salesVolumeType:true,
      isPriceType:true,

      history: JSON.parse(localStorage.getItem("searchHistory")) || [], // 历史记录
      searchinp:'',
    };
  },
  watch: {
    $route: {
      handler: function(val) {
        //这里的路由是页面上的路由
        if (val.path == '/commodityDetail') {

        }
      },
      deep: true
    }
  },
  methods: {
    clickSearch(value) {
      this.searchinp = value;
      this.search();
      this.reqCategoryName();
    },
    
    search() {
      this.pageIndex = 0;
      this.numPerPage = 10;
      let searchinp = this.ruleText(this.searchinp);
      this.queryMess = searchinp;
      if (searchinp != "") {
        let searchHistory = this.history;
        searchHistory.map((item, index) => {
          if (searchinp == item) {
            searchHistory.splice(index, 1);
          }
        });
        searchHistory.unshift(searchinp);
        localStorage.setItem("searchHistory", JSON.stringify(searchHistory));
      }
    },
    ruleText(text) {
      return text.replace(/(^\s*)|(\s*$)/g, "");
    },

    onPaging(page, pageSize){
      this.pageIndex = page-1;
      this.numPerPage = pageSize;
      this.reqCategoryName();
    },

    // 加入选品购物车
    oneClickOption(nbr){
      if(this.userInfo && this.userInfo.nbr == ""){
        return this.$message.warning('您还未登录，登录后即可选品');
      }else{
        let that = this;
        this.$commonPost({
          jydm: "flow_170001",
          flow_170001: {
            commodity_nbr:nbr,
            user_nbr:that.userInfo.nbr,
            quantity:"1",
          },
        }).then(resp=>{
          if(resp.resp_code === "0"&& resp.resp_result==="0"){
            that.$message.success('选品'+resp.resp_msg);
          }
        }).catch(err=>{
          that.$message.error(err.message);
        })
      }
    },

    getSort(type){
      this.sortIndex = type;
      if(this.sortIndex == 2){
        this.sortOrder = 2;
      }else{
        this.sortOrder = 1;
      }

      if(this.sortIndex == 3){
        this.salesVolumeType = !this.salesVolumeType;
        this.salesVolume = this.salesVolumeType == true ? '销量(低)' : '销量(高)';
        this.sortOrder = this.salesVolumeType == true ? '1' : '2';
      }else{
        this.salesVolume = "销量";
      }

      if(this.sortIndex == 4){
        this.isPriceType = !this.isPriceType;
        this.isPrice = this.isPriceType == true ? '价格(低)' : '价格(高)';
        this.sortOrder = this.isPriceType == true ? '1' : '2';
      }else{
        this.isPrice = "价格";
      }
      this.reqCategoryName();
    },

    goDetail(nbr){
      this.$router.push({
        path:'/commodityDetail',
        query:{ commNbr:nbr }
      })
    },

    // 搜索查询
    reqCategoryName(){
      let that = this;
      that.searchinp = that.queryMess
      that.search();
      // that.clickSearch(that.queryMess);
      that.queryFlag = 1;
      that.$commonPost({
        jydm: "flow_70001",
        flow_70001: {
          category_nbr: '',
          commodity_name: that.queryMess,
          page_index: that.pageIndex * that.numPerPage,
          num_per_page: that.numPerPage,
          type: "2",
          sort_type: that.sortIndex,
          sort_order: that.sortOrder,
        },
      })
        .then((response) => {
          let arr = [];
          if (response.total_rows > 0) {
           let resp = response.commodityList;
            if (resp.commodity.length > 0) {
              arr = resp.commodity;
            } else {
              arr.push(resp.commodity);
            }
          }
          that.list = [];
          that.list = that.list.concat(arr);
          that.totalNum = Number(response.total_rows);
        })
        .catch((err) => {
          message.error(err.message)
        });
    },
  },
  activated(){},
  mounted() {
    this.list = [];
  },
  created() {
    
  },
};
</script>
<style lang="less" scoped>
.CommodityPoints {
  width: 100%;
  margin: 0 auto;
  background: #f0f0f0;
  padding-bottom: 20px;
}
.commPointsBox {
  width: 1190px;
  margin: 0 auto;
}

.searchBox {
  margin: 0 auto;
  padding-top: 40px;
  padding-bottom: 40px;
  .searchFrameBox {
    cursor:pointer;
    margin: 0 auto;
    width: 796px;
    height: 42px;
    display: flex;
    .searchFrame {
      cursor:pointer;
      width: 696px;
      height: 42px;
      font-size: 14px;
      color: #999999;
      background: #ffffff;
      border: 1px solid #ff0000;
      padding-left: 10px;
      line-height: 42px;
      display: flex;
      align-items: center;
      input{
        border: 0px;
        width: 600px;
        height: 40px;
      }
    }
    .searchFrameBtn {
      cursor:pointer;
      width: 120px;
      height: 42px;
      background: #f32f2f;
      font-size: 18px;
      color: #ffffff;
      text-align: center;
      line-height: 42px;
    }
  }
}
.searchHistory {
  width: 1170px;
  margin: 0 auto;
  padding-bottom: 40px;
  .historyBox{
    display: flex;
    .historyFrame{
      flex: 15;
      display: flex;
      flex-wrap: wrap;
      span{
        cursor: pointer;
        margin-right: 10px;
      }
    }
  }
}

// 专场
.specialSessionBox {
  margin-top: 10px;
  .specialSession {
    background: #fff;
    border-radius: 6px;
    .specialTitleBox {
      height: 54px;
      padding: 10px 0 10px 10px;
      border-bottom: 1px #f3f3f3 solid;
      margin-bottom: 10px;
      .specialTitle {
        width: 108px;
        height: 34px;
        background: #ff2727;
        border-radius: 17px;
        text-align: center;
        line-height: 34px;
        font-size: 16px;
        color: #ffffff;
      }
    }
    .specialSessionListBox {
      width: 100%;
      margin: 10px 0 10px 0;
      padding-bottom: 20px;
      .specialListBox {
        display: flex;
        flex-wrap: wrap;
        margin: 0 0px 0 10px;
        .ListBox {
          margin-right: 18px;
          width: 218px;
          // height: 285px;
          margin-bottom: 20px;
          margin-top: 20px;
          .ListBoxImg {
            width: 218px;
            height: 218px;
            border-radius: 6px;
          }
          .nameBox{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 98px;
            .isName {
              font-size: 16px;
              color: #333333;
              text-overflow:-o-ellipsis-lastline; 
              overflow:hidden; text-overflow: ellipsis; 
              display: -webkit-box; -webkit-line-clamp: 2; 
              -webkit-box-orient: vertical;
              margin-top: 10px;
            }
            .bntBox{
              margin-top: 10px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              .isPrice {
                font-size: 18px;
                color: #ff0000;
              }
              .btn{
                cursor: pointer;
                padding: 0 10px;
                height: 30px;
                color: #fff;
                text-align: center;
                line-height: 30px;
                background-color: #ff0000;
                border-radius: 40px;
              }
            }
          }
        }
      }
    }
  }
}
.pagination{
  background: #fff;
  width: 1190px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
}
.firstLevelBox {
  width: 100%;
  margin-top: -1px;
  .firstLevel {
    width: 100%;
    background: #ffffff;
    border: 1px solid #d5d5d5;
    display: flex;
    flex-wrap: wrap;
    padding-left: 10px;
    .fatherBox {
      // margin: 10px;
      font-size: 14px;
      color: #999999;
      line-height: 44px;
    }
    .father {
      line-height: 44px;
      font-size: 14px;
      color: #333333;
      padding: 0 10px 0 10px;
      background: #fff;
      cursor:pointer;
    }
    .fatherCheck {
      cursor:pointer;
      line-height: 44px;
      font-size: 14px;
      color: #333333;
      padding: 0 10px 0 10px;
      background: #D5D5D5;
    }
    .childrenCheck{
      cursor:pointer;
      line-height: 44px;
      font-size: 14px;
      color: #FF0000;
      padding: 0 10px 0 10px;
    }
    .sortCheck{
      cursor:pointer;
      line-height: 44px;
      font-size: 14px;
      color: #333;
      font-weight: bold;
      padding: 0 10px 0 10px;
    }
    .sortFather {
      line-height: 44px;
      font-size: 14px;
      color: #333333;
      padding: 0 10px 0 10px;
      background: #fff;
      cursor:pointer;
    }
  }
}
</style>
<style scoped>
</style>
