import Vue from 'vue'
import { message } from 'ant-design-vue';

message.config({
  duration: 2, // 提示时常单位为s
  top: '40px', // 距离顶部的位置
  maxCount: 3 // 最多显示提示信息条数(后面的会替换前面的提示)
})

Vue.use(message);

// TODO:姓名第一个字符以星号替换
export function codeName(itemName) {
    if (itemName) {
        let reg = /^./;
        return itemName.replace(reg, "*");
    } else {
        return "暂无姓名";
    }
}

// TODO:手机号中间五位字符以星号替换
export function codeNumber(itemNumber) {
    if (itemNumber) {
        let reg = /(\d{3})\d{5}(\d{3})/;
        return itemNumber.replace(reg, "$1*****$2");
    } else {
        return "暂无手机号";
    }
}

//验证手机号
export function checkphone(phone) {
    const reg = /^1[3456789]\d{9}$/;
    // const reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/; //11位手机号码正则
    if (!reg.test(phone)) {
      message.warning("请输入正确手机号码！");
        return false;
    } else {
        return true;
    }
}

//验证手机号码和座机号码
export function checkphoneandtelephone(phone) {
    var isPhone = /^1[3456789]\d{9}$/;
    var isMob = /^((\+?86)|(\(\+86\)))?(13[012356789][0-9]{8}|15[012356789][0-9]{8}|18[02356789][0-9]{8}|147[0-9]{8}|1349[0-9]{7})$/;
    if (isMob.test(phone) || isPhone.test(phone)) {
        return true;
    } else {
      message.warning("请输入正确联系方式！");
        return false;
    }
}

//验证身份证号码
export function checkidentificationcard(identificationcard) {
    const reg = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
    if (!reg.test(identificationcard)) {
      message.warning("请输入正确身份证号码！");
        return false;
    } else {
        return true;
    }
}

//验证非空输入
export function checkempty(val, toast) {
    if (val === "" || val === false || val === null || val.length == 0) {
        if (toast) {
          message.warning(toast);
        }
        return false;
    } else {
        return true;
    }
}

/**
 * 验证账户名
 */
export function checkAccount(o) {
    // const re = /^(?![A-Za-z0-9]+$)(?![a-z0-9\W]+$)(?![A-Za-z\W]+$)(?![A-Z0-9\W]+$)[a-zA-Z0-9\W]{8,18}$/
    // const re=/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,18}$/;
    const re = /^[0-9A-Za-z]{6,18}$/;
    if (!re.test(o)) {
        return false;
    } else {
        return true;
    }
}

/**
 * 验证密码
 */
export function checkPsw(o) {
    // const re = /^(?![A-Za-z0-9]+$)(?![a-z0-9\W]+$)(?![A-Za-z\W]+$)(?![A-Z0-9\W]+$)[a-zA-Z0-9\W]{8,18}$/
    // const re=/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,18}$/;
    const re = /^[0-9A-Za-z\W]{6,18}$/;
    // const re = /^(?=.*[0-9].*)(?=.*[A-Za-z].*).{6,18}$/;
    if (!re.test(o)) {
        return false;
    } else {
        return true;
    }
}

/**
 * 验证密码
 */
export function checkTrPsd(o) {
    // const re = /^(?![A-Za-z0-9]+$)(?![a-z0-9\W]+$)(?![A-Za-z\W]+$)(?![A-Z0-9\W]+$)[a-zA-Z0-9\W]{8,18}$/
    // const re=/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,18}$/;
    const re = /^[0-9A-Za-z\W]{6}$/;
    // const re = /^(?=.*[0-9].*)(?=.*[A-Z].*)(?=.*[a-z].*).{8,18}$/;
    if (!re.test(o)) {
        return false;
    } else {
        return true;
    }
}

/**
 * ja压缩图片
 * @param 2019.1.18
 * @returns {string}
 */
/**
        三个参数
        file：一个是文件(类型是图片格式)，
        objDiv：一个是容器或者回调函数
        photoCompress()
        quality压缩比例 .
         */
export function photoCompress(file, objDiv, quality) {
    console.log(quality);
    var ready = new FileReader();
    /*开始读取指定的Blob对象或File对象中的内容. 当读取操作完成时,readyState属性的值会成为DONE,如果设置了onloadend事件处理程序,则调用之.同时,result属性中将包含一个data: URL格式的字符串以表示所读取文件的内容.*/
    ready.readAsDataURL(file);
    ready.onload = function() {
        var re = this.result;
        canvasDataURL(re, objDiv, quality)
    }
}

function canvasDataURL(path, callback, quality) {
    console.log(quality);
    var img = new Image();
    img.src = path;
    img.onload = function() {
        var that = this;
        // 默认按比例压缩
        var w = that.width,
            h = that.height,
            scale = w / h;
        w = w;
        h = (w / scale);
        // console.log('w:'+w,'h:'+h)
        // var quality = quality;  // 默认图片质量为0.7
        //生成canvas
        var canvas = document.createElement('canvas');
        var ctx = canvas.getContext('2d');
        // 创建属性节点
        var anw = document.createAttribute("width");
        anw.nodeValue = w;
        var anh = document.createAttribute("height");
        anh.nodeValue = h;
        canvas.setAttributeNode(anw);
        canvas.setAttributeNode(anh);
        ctx.drawImage(that, 0, 0, w, h);
        // 图像质量
        // if(obj.quality && obj.quality <= 1 && obj.quality > 0){
        //   quality = obj.quality;
        // }
        // quality值越小，所绘制出的图像越模糊
        var base64 = canvas.toDataURL('image/jpeg', quality);
        // 回调函数返回base64的值
        callback(base64);
    }
}

//将base64转换为文件  返回的就是一个文件
export function dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
}


//  下面是vue过滤器
//时间戳转换成时间2018-04-23
export function timestampconvert(time) {
    time = new Date(time * 1000);
    return time.getFullYear() + "/" + (time.getMonth() + 1) + "/" + time.getDate();
}
//时间戳转换成时间2018-04-23 11:08
export function timestampconvertcolon(time) {
    time = new Date(time * 1000);
    return time.getFullYear() + "-" + (time.getMonth() + 1) + "-" + time.getDate() + " " + time.getHours() + ":" + time.getMinutes();
}
//电话 138****9999
export function phonehide(phone) {
    return phone.slice(0, 3) + '****' + phone.slice(7, 11)
}
// 金钱格式化
export function formatMoney(money) {
    if (money && money != null) {
        money = String(money);
        var left = money.split('.')[0],
            right = money.split('.')[1];
        right = right ? (right.length >= 2 ? '.' + right.substr(0, 2) : '.' + right + '0') : '.00';
        var temp = left.split('').reverse().join('').match(/(\d{1,3})/g);
        return (Number(money) < 0 ? "-" : "") + temp.join(',').split('').reverse().join('') + right;
    } else if (money === 0) {
        //注意===在这里的使用，如果传入的money为0,if中会将其判定为boolean类型，故而要另外做===判断
        return '0.00';
    } else {
        return "";
    }
}
//昵称去除图片
export function formatNickName(str) {
    let strArr = str.split(""),
        result = "",
        totalLen = 0;
    for (let idx = 0; idx < strArr.length; idx++) {
        let val = strArr[idx];
        // 英文,增加长度1
        if (/[a-zA-Z0-9]/.test(val)) {
            totalLen = 1 + (+totalLen);
            result += val;
        }
        // 中文,增加长度2
        else if (/[\u4e00-\u9fa5]/.test(val)) {
            totalLen = 2 + (+totalLen);
            result += val;
        }
        // 遇到代理字符,将其转换为 "", 不增加长度
        else if (/[\ud800-\udfff]/.test(val)) {
            // 代理对长度为2,
            if (/[\ud800-\udfff]/.test(strArr[idx + 1])) {
                // 跳过下一个
                idx++;
            }
            // 将代理对替换为 ""
            result += "";
        }
    };
    result = formatSQL(result)
    return result;
}

//去除SQL特殊符号
export function formatSQL(s) {
    let pattern = new RegExp("['|(--)]")
    let rs = "";
    for (let i = 0; i < s.length; i++) {
        rs = rs + s.substr(i, 1).replace(pattern, '');
    }
    return rs;
}

//特殊字符处理
export function handleText(text) {
    text = text.replace(/\$~1~\$/g, '%');
    text = text.replace(/\$~2~\$/g, '--');
    text = text.replace(/\$~3~\$/g, '+');
    return text;
}

export function handleText_anti(text) {
    text = text.replace(/%/g, '$~1~$');
    text = text.replace(/--/g, '$~2~$');
    return text;
}


// 存cookies
export function setCookie(key, value, days) {
    let exp = new Date();
    exp.setTime(exp.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie = key + '=' + value + ";expires=" + exp.toGMTString();
}

// 取cookies
export function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

// 数字转换亿和万
export function StandardNum(num) {
    num = parseFloat(num || '0')
    if (num >= 10000 * 10000) {
        return parseFloat((num / (10000 * 10000)).toFixed(1)) + '亿'
    } else if (num >= 10000) {
        return parseFloat((num / 10000).toFixed(1)) + '万'
    } else {
        return num
    }
}

const tool = {
    timestampconvert,
    timestampconvertcolon,
    phonehide,
    formatMoney,
};
Object.keys(tool).forEach(key => {
    Vue.filter(key, tool[key]);
});