<template>
  <div class="commStatistics">
    <div style="height: 64px; width: 100%"></div>
    <div class="resultOrderBox" v-if="CommoList.length>0">


      <!-- 列表 -->
      <div class="specialSession">
      <!-- 筛选 -->
      <div class="firstLevelBox" style="margin-top: -1px;position: fixed;" v-if="CommoList.length>0">
        <div class="firstLevel">
          <div class="fatherBox">商品筛选：</div>
          <div style="display: flex;">
            <div :class="sortIndex == 0 ? 'sortCheck' : 'sortFather'" @click="getSort(0)">默认</div>
            <div :class="sortIndex == 1 ? 'sortCheck' : 'sortFather'" @click="getSort(1)">{{isPrice}}</div>
            <div :class="sortIndex == 2 ? 'sortCheck' : 'sortFather'" @click="getSort(2)">{{salesVolume}}</div>
            <div :class="sortIndex == 3 ? 'sortCheck' : 'sortFather'" @click="getSort(3)">{{newTime}}</div>
          </div>
        </div>
        <div style="height: 46px; width: 100%"></div>
        </div>

        <div class="specialTitleBox">
          <span class="specialTitle1">{{ areaTitle }}</span>
        </div>
        <div style="height: 94px; width: 100%"></div>
        <div class="specialSessionBannerBox"><img :src="imagePath" /></div>
        <div class="specialSessionListBox">
          <div class="specialListBox">
            <div class="ListBox" v-for="(item) in CommoList" :key="item.nbr" @click="goDetail(item.nbr)">
              <img class="ListBoxImg" :src="item.commodity_logo" alt="" />
              <div>{{item.commodity_name}}</div>
              <div>￥{{item.min_sell_price}}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 分页 -->
      <div style="height: 64px; width: 100%"></div>
      <div class="pagination">
        <a-pagination href="javascript:;" @change="onPaging" :total="totalNum"/>
      </div>
    </div>
  </div>
</template>
  
<script>
import { mapGetters } from "vuex";
export default {
  name: "specialSession",
  data(){
    return{
      isPrice:'价格',
      salesVolume:"销量",
      newTime:'最新',
      sortIndex:0,
      sortOrder:1,

      salesVolumeType:true,
      isPriceType:true,

      CommoList:[],
      areaTitle:'',
      imagePath:'',
      dataNbr:'',
      pageIndex:0,
      numPerPage:10,
      totalNum:0,
    }
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  methods:{
    onPaging(page, pageSize){
      this.pageIndex = page-1;
      this.numPerPage = pageSize;
      this.getSpecialCommo();
    },

    getSort(type){
      // 0默认 1价格 2 销量 3时间
      this.sortIndex = type;
      if(this.sortIndex == 0){
        this.sortOrder = 1;
      }else{
        this.sortOrder = 1;
      }

      if(this.sortIndex == 2){
        this.salesVolumeType = !this.salesVolumeType;
        this.salesVolume = this.salesVolumeType == true ? '销量(低)' : '销量(高)';
        this.sortOrder = this.salesVolumeType == true ? '1' : '2';
      }else{
        this.salesVolume = "销量";
      }

      if(this.sortIndex == 1){
        this.isPriceType = !this.isPriceType;
        this.isPrice = this.isPriceType == true ? '价格(低)' : '价格(高)';
        this.sortOrder = this.isPriceType == true ? '1' : '2';
      }else{
        this.isPrice = "价格";
      }
      this.getSpecialCommo();
    },

    getSpecialCommo(){
      let that = this;
      that.$commonPost({
        jydm: "flow_70013",
        flow_70013: {
          area_nbr: that.dataNbr,
          user_nbr:that.userInfo.nbr,
          type:'-1',
          page_index:that.numPerPage * that.pageIndex,
          num_per_page:that.numPerPage,
          sort_type: that.sortIndex,
          sort_order: that.sortOrder,
        },
      }).then(resp => {
        if(resp.resp_code==0 && resp.resp_result==0){
          that.areaTitle = resp.area_title;
          that.imagePath = resp.image_path;
          let arr = [];
          if(resp.commodity.length>0){
            arr = resp.commodity
          }else{
            arr.push(resp.commodity)
          }
          that.totalNum = Number(resp.total_rows);
          that.CommoList = [];
          that.CommoList = arr;
        } else {
          that.$message.error(resp.resp_msg)
        }
      })
      .catch((err) => {
        that.$message.error(err.message)
      });
    },
    goDetail(nbr){
      this.$router.push({
        path:'/commodityDetail',
        query:{ commNbr:nbr }
      })
    },
  },
  mounted() {},
  activated(){
    if(this.$route.meta){
      this.dataNbr = this.$route.query.areaNbr;
      let dataNbr1 = window.localStorage.getItem('dataNbr');
      if(this.dataNbr != dataNbr1 && this.dataNbr != undefined){
        window.localStorage.setItem('dataNbr',this.dataNbr);
        this.dataNbr = window.localStorage.getItem('dataNbr');
      }else{
        this.dataNbr = window.localStorage.getItem('dataNbr');
      }
      this.getSpecialCommo();
    }
  },
  created() {
    this.dataNbr = this.$route.query.areaNbr;
    console.log(this.dataNbr);
    if(this.dataNbr != "" && this.dataNbr != undefined){
    this.getSpecialCommo();
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.firstLevelBox {
  width: 100%;
  margin-top: -1px;
  .firstLevel {
    width: 100%;
    background: #ffffff;
    border: 1px solid #d5d5d5;
    display: flex;
    flex-wrap: wrap;
    padding-left: 10px;
    .fatherBox {
      // margin: 10px;
      font-size: 14px;
      color: #999999;
      line-height: 44px;
    }
    .sortCheck{
      cursor:pointer;
      line-height: 44px;
      font-size: 14px;
      color: #333;
      font-weight: bold;
      padding: 0 10px 0 10px;
    }
    .sortFather {
      line-height: 44px;
      font-size: 14px;
      color: #333333;
      padding: 0 10px 0 10px;
      background: #fff;
      cursor:pointer;
    }
  }
}
.commStatistics {
  width: 100%;
  margin: 0 auto;
  background: #f0f0f0;
  padding-bottom: 20px;
}
.resultOrderBox {
  width: 1190px;
  margin: 0 auto;
}
.pagination{
  background: #fff;
  width: 1190px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
}
.specialSession {
  background: #fff;
  border-radius: 6px;
  padding-bottom: 10px;
  .specialTitleBox {
    display: flex;
    justify-content: space-between;
    width: 1190px;
    height: 54px;
    padding: 10px 0 10px 10px;
    border-bottom: 1px #f3f3f3 solid;
    margin-bottom: 10px;
    position: absolute;
    background: #fff;
    top: 109px;
    z-index :999;
    .specialTitle1 {
      padding: 6px 10px 6px 10px;
      background: #ff2727;
      border-radius: 17px;
      text-align: center;
      font-size: 16px;
      color: #ffffff;
    }
    .specialTitle2 {
      cursor: pointer;
      padding: 6px 10px 6px 10px;
      border-radius: 17px;
      text-align: center;
      font-size: 16px;
      color: #999;
      margin-right:10px;
    }
  }
  .specialSessionBannerBox {
    width: 100%;
    height: 260px;
    border-radius: 6px;
    margin: 10px 0 10px 0;
    img {
      margin: 0 10px 0 10px;
      width: 1170px;
      height: 260px;
    }
  }
  .specialSessionListBox {
    width: 100%;
    margin: 10px 0 10px 0;
    .specialListBox {
      display: flex;
      flex-wrap: wrap;
      margin: 0 0px 0 10px;
      .ListBox {
        margin-right: 18px;
        width: 218px;
        // height: 285px;
        margin-bottom: 20px;
        .ListBoxImg {
          width: 218px;
          height: 218px;
          border-radius: 6px;
        }
        :nth-child(2) {
          font-size: 16px;
          color: #333333;
          margin-top: 10px;
          text-overflow:-o-ellipsis-lastline;
          overflow:hidden; 
          text-overflow: ellipsis; 
          display: -webkit-box; 
          -webkit-line-clamp: 2; 
          -webkit-box-orient: vertical;
        }
        :nth-child(3) {
          font-size: 18px;
          color: #ff0000;
          margin-top: 10px;
        }
      }
    }
  }
}
</style>
