<template>
  <div class="resultOrder">
    <div style="height: 64px; width: 100%"></div>
    <div class="resultOrderBox">
      <!-- 时间选择 -->
      <div class="queryBox">
        <div class="inputQueryBox">
          <div class="timeQuery">下单时间：<a-range-picker format="YYYY-MM-DD" :placeholder=[timeData[0],timeData[1]]
          :default-value="[moment(timeData[0]), moment(timeData[1])]" :value="[moment(timeData[0]), moment(timeData[1])]"
          :inputReadOnly="true" :locale="locale"  @change="onChange" /></div>
          <div class="conditionQuery">条件查询：<a-input style="width:272px" v-model="queryMess" placeholder="请输入订单编号" /></div>
        </div>

        <!-- 订单状态 -->
        <div class="orderStatus">
          订单状态：<a-button :type="finalState == 4 ? 'primary' : ''" class="bePaid" @click="finalState = 4">待支付</a-button>
          <a-button :type="finalState == 6 ? 'primary' : ''" @click="finalState = 6">已支付</a-button>
        </div>

        <!-- 确认按钮 -->
        <div class="orderStatus">
          <a-button type="primary" @click="searchList" class="bePaid">搜索</a-button>
          <a-button @click="resetList">重置</a-button>
        </div>
      </div>

      <!-- 表格 -->
      <div class="formBox">
        <table>
          <tr>
            <th style="width: 140px;">订单编号</th>
            <th style="width: 140px;">订单创建时间</th>
            <th style="width: 138px;">小订单总数</th>
            <th style="width: 114px;">订单总价</th>
            <!-- <th style="width: 112px;">运费</th> -->
            <th style="width: 132px;">付款时间</th>
            <th style="width: 140px;">订单状态</th>
            <th style="width: 242px;">操作</th>
          </tr>
          <tr class="myStyle" :class="index % 2 == 0 ? 'myStyle1 myStyle2' : 'myStyle myStyle2'" v-for="(item,index) in list" :key="item.key">
            <td>{{item.show_nbr || "暂无"}}</td>
            <td>{{item.create_time || "暂无"}}</td>
            <td>{{item.quantity || "暂无"}}</td>
            <td>{{item.sum_amount || "暂无"}}</td>
            <!-- <td>{{item.postage || "暂无"}}</td> -->
            <td>{{item.pay_create_time || "暂无"}}</td>
            <td>{{item.final_state == 4? '未支付' : (item.final_state == 6 && item.state && item.state == 1) ? '已支付(待审核)'
              :(item.final_state == 6 && item.state && item.state == 3) ? '已支付(审核通过)' 
              :(item.final_state == 6 && item.state && item.state == 4) ? '已支付(审核失败)'
              :item.final_state == 6 ? '已支付':item.final_state == 7? '已发货':item.final_state == 8? '已收货'
              :item.final_state == 10? '关闭':'暂无'}}
            </td>
            <td style="width: 240px;display: flex; align-items: center;justify-content: center;color:#007AFF;">
              <div class="btn">
                <div :class=" item.final_state == 10 || item.state == 3 || item.final_state == 6?'aloneSeeBtn':'seeBtn'"
                @click="goPage(item.order_nbr)">查看订单商品</div>
                <div v-if="item.final_state == 4 || (item.state == 4 && item.final_state == 4 )"
                @click="goPay('1',item.order_nbr)">去支付</div>
<!--                 
                <div v-if="item.state == 3 || item.final_state == 6" @click="goPay('2',item.order_nbr)">支付详情</div> -->
              </div>
            </td>
          </tr>
        </table>
      </div>
      <div style="height: 64px; width: 100%"></div>
      <!-- 分页 -->
      <div class="pagination">
        <a-pagination href="javascript:;" @change="onPaging" :total="totalNum" show-less-items />
      </div>
    </div>
  </div>
</template>
 
<script>
// 默认语言为 en-US，如果你需要设置其他语言，推荐在入口文件全局设置 locale
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import 'moment/locale/zh-cn';
import moment from 'moment';
import {mapGetters} from "vuex"
export default {
  name: "ResultOrder",
  components: {},
  data() {
    return {
      locale,
      list: [],
      queryMess:"",
      timeData:[],
      finalState:-1,
      pageIndex:0,
      numPerPage:10,
      totalNum:0,
      nowDate1:null,
      nowDate2:null,
      
    };
  },
  computed:{
    ...mapGetters(['userInfo'])
  },

  methods: {
    moment,

    onPaging(page, pageSize){
      this.pageIndex = page-1;
      this.numPerPage = pageSize;
    },

    searchList(){
      let that = this;
      that.reqSettlementList();
    },

    resetList(){
      let that = this;
      that.finalState = -1;
      that.timeData[0] = this.getLastDay();
      that.timeData[1] = this.getLastAfterDay();
      that.queryMess = "";
      that.reqSettlementList();
    },

    onChange(date, dateString) {
      this.timeData = dateString;
    },

    goPay(type,nbr){
      if(type == '1'){
        this.$router.push({
          path:'/selectPayment',
          query:{orderNbr : nbr}
        })
      }
    },

    goPage(nbr){
      this.$router.push({
        path:'/orderCommodity',
        query:{orderNbr : nbr}
      })
    },

    reqSettlementList(){
      let that = this;
      this.$commonPost({
        jydm: "flow_30065",
        flow_30065: {
          start_time:that.timeData[0],
          end_time:that.timeData[1],
          user_nbr:that.userInfo.nbr,
          search:that.queryMess,
          page_index:that.numPerPage * that.pageIndex,
          num_per_page:that.numPerPage,
          final_state:that.finalState,
        },
      }).then(resp=>{
       if(resp.resp_code==0 && resp.resp_result==0){
        that.list = [];
        let arr = [];
        if(resp.list){
          if(resp.list.length>0) arr = resp.list;
          else arr.push(resp.list);
        }
        that.list = arr;
        that.totalNum = Number(resp.total_rows);
       }else{
        that.$message.error(resp.resp_msg)
       }
      })
      .catch(err=>{
        that.$message.error(err.message)
      })
    },
    


    getLastDay(){
      var y = new Date().getFullYear(); //获取年份
      var m = new Date().getMonth() + 1; //获取月份
      var d = '01'
      m = m < 10 ? '0' + m : m; //月份补 0
      return [y,m,d].join("-")
    },
    getLastAfterDay(){
      var y = new Date().getFullYear(); //获取年份
      var m = new Date().getMonth() + 1; //获取月份
      var d = new Date(y, m, 0).getDate(); //获取当月最后一日
      m = m < 10 ? '0' + m : m; //月份补 0
      d = d < 10 ? '0' + d : d; //日数补 0
      return [y,m,d].join("-")
    },
  },
  mounted() {},
  created() {
    this.timeData[0] = this.getLastDay();
    this.timeData[1] = this.getLastAfterDay();
    this.reqSettlementList();
  },
  activated(){},
};
</script>
<style lang="less" scoped>
.resultOrder {
  width: 100%;
  margin: 0 auto;
  background: #f0f0f0;
  padding-bottom: 20px;
}
.seeBtn{
  width: 100px; 
  margin-right: 10px;
  border-right: 1px #D8D8D8 solid; 
  padding-right: 10px;
}
.aloneSeeBtn{
  width: 100px;
}
.resultOrderBox {
  width: 1190px;
  margin: 0 auto;
}
.queryBox {
  height: 230px;
  background: #fff;
  padding-left: 32px;
  .inputQueryBox{
    height: 90px;
    display: flex;
    align-items: center;
    border-bottom: 1px #f0f0f0 solid;
    .conditionQuery{
      width: 470px;
      margin-left: 40px;
    }
  }
  .orderStatus{
      height: 60px;
      display: flex;
      align-items: center;
      .bePaid{
        margin-right: 20px;
      }
  }
}
.formBox{
  background: #fff;
  margin-top: 10px;
  font-size: 12px;
  .viewOrder{
    width: 72px;
    height: 15px;
    padding-right: 10px;
    border-right: 1px #D8D8D8 solid;
  }
  table{
    width: 100%;
    text-align: center;
    border: 0px;
    th{
      border-bottom:1px solid #DCDEE3;
      background: #EBECF0;
      padding: 10px;
    }
    td{
       height: 120px;
    }
  }

  .myStyle2:hover{
    background: #F2F2F2;
  }
  .myStyle{
    background: #f8f8f8;
  }
  .myStyle1{
    background: #fff;
  }
}
.btn{
  cursor: pointer;
  display: flex;
}
.btn div{
  font-weight: bold;
  color:#007AFF;
}
.pagination{
  background: #fff;
  width: 1190px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
}
</style>
<style scoped>
</style>
