import Vue from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';
import './assets/css/reset.css';
import store from './store/index';
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import md5 from 'js-md5';

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'//引入样式
Vue.use(VueAwesomeSwiper, /* { 全局组件的默认选项 } */)

import { message } from 'ant-design-vue';

import { commonPost, post, fetch, patch, put } from './assets/js/httpRequestUtil';
import VueAxios from 'vue-axios';

//定义全局变量
Vue.prototype.aliImgPath = 'https://sbjssupplierimages.sabmk.cn'; // 阿里云图片地址
Vue.prototype.huaweiImgPath = 'https://jlmimages.cqbangsheng.com'; // 华为云图片地址
Vue.config.productionTip = false;
Vue.prototype.$message = message;
Vue.prototype.md5 = md5;
Vue.prototype.$commonPost = commonPost;
Vue.prototype.$post = post;
Vue.prototype.$fetch = fetch;
Vue.prototype.$patch = patch;
Vue.prototype.$put = put;
Vue.use(VueAxios, axios);
Vue.use(Antd);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
