//根据事件名称修改数据库


import * as types from './mutation_types.js'

const mutations = {
    [types.SENDselectaddress](state, selectaddress) {
        state.selectaddress = selectaddress;
    },
    [types.SENDpayment](state, payment) {
        state.payment = payment;
    },
    [types.SENDuserInfo](state, userInfo) {
        state.userInfo = userInfo;
    },
    [types.SENDToken](state, token) {
        state.token = token;
    },
}

export default mutations;