<template>
  <div class="shoppingCart">
    <div style="height: 64px; width: 100%"></div>
    <div class="shoppingCartBox">
      <div class="shoppingCartTop">
        <div class="cartTextBox">
          <div class="cartText">购物车</div>
          <!-- <div class="cartSelectAll" @click="onCheckAllChange">
            <img
              v-if="indeterminate"
              src="../../assets/img/redGroup.png"
              alt=""
            />
            <img v-else src="../../assets/img/rectangle.png" alt="" />
            <div>全选</div>
          </div> -->
          <a-button type="dashed" class="cartEliminate" @click="showDeleteConfirm">清空失效宝贝</a-button>
          <a-button type="dashed" class="cartEliminate" @click="batchDeleteCommodity">批量删除宝贝</a-button>
        </div>
        <div class="cartBtn">
          <div class="sumPrice">
            <!-- 总价：<span>￥{{ cartSum }}</span> -->
          </div>
          <div class="synchronization" @click="showClickSync">一键同步至商户平台</div>
        </div>
      </div>
      <!-- 分页 -->
      <div class="pagination">
       <a-pagination href="javascript:;" @change="onPaging" :total="totalRows" show-less-items />
    </div>

    </div>
    <div class="shoppingListBox">
      <a-spin :spinning="spinning">
        <div v-for="(item, index) in list" :key="item.nbr">
          <Cart
            :listItem="item"
            :index="index"
            :setCheckedType="setCheckedType"
            :reqEliminates="reqEliminate"
            :key="item.checked"
          />
        </div>
      </a-spin>

      <!-- 失效商品 -->
      <div
        v-for="(item, index) in invalidList"
        :key="item.key"
      >
        <Cart
          :listItem="item"
          :index="index"
          :setCheckedType="setCheckedType"
          :reqEliminates="reqEliminate"
        />
      </div>
    </div>
    
  </div>
</template>
<script>
import Cart from "../../components/cart.vue";
import { message } from "ant-design-vue";
import { mapGetters } from "vuex";
export default {
  name: "ShoppingCart",
  components: {
    Cart,
  },
  data() {
    return {
      arrList:[],// 被选中的商品编号
      spinning:false,
      totalRows:0,
      cartSum: 0,
      checkedList: [],
      indeterminate: false,
      info: "",
      invalidList: [],
      list: [],
      checkData:[],
      numPerPage:10,
      pageIndex:0,
    };
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  methods: {
    // 分页
    onPaging(page,pageSize){
       this.pageIndex = page-1;
      this.numPerPage = pageSize;
      this.reqCartList();
    },

    // 清空失效确认弹窗
    showDeleteConfirm() {
      let that = this;
      if (this.invalidList.length == 0) {
        return message.success("暂无失效商品");
      } else {
        this.$confirm({
          title: "确认清空所有失效商品嘛?",
          okText: "确认",
          okType: "danger",
          cancelText: "取消",
          onOk() {
            that.reqEliminate("1");
          },
          onCancel() {},
        });
      }
    },

    // 批量删除确认弹窗
    batchDeleteCommodity() {
      let that = this;
      if (this.arrList.length == 0) {
        return message.success("记得选择要删除的商品哦~");
      } else {
        this.$confirm({
          title: "确认要删除的商品嘛?",
          okText: "确认",
          okType: "danger",
          cancelText: "取消",
          onOk() {
            that.reqEliminate("3");
          },
          onCancel() {},
        });
      }
    },

    // 一键同步确认弹窗
    showClickSync() {
      let that = this;
      if (this.list.length == 0) {
        return message.success("暂无商品");
      } else {
        this.$confirm({
          title: "确认同步所有商品嘛?",
          okText: "确认",
          okType: "danger",
          cancelText: "取消",
          onOk() {
            that.oneClickSync();
          },
          onCancel() {},
        });
      }
    },

    // 一键同步
    oneClickSync(){
      let that = this;
      this.spinning = true;
      this.$post("api/customer/choose/transferCommodityToMerchant",{
        userNbr:that.userInfo.nbr,
      }).then(resp=>{
        if(resp.code == 200){
          let res = resp.object.resp;
          if (res.resp_code == "0" && res.resp_result == "0") {
            this.spinning = false;
            this.$message.success('同步成功', 2.5);
            that.reqCartList();
          }else{
            this.spinning = false;
            that.$message.success(res.resp_msg)
          };
        }else{
          this.spinning = false;
          that.$message.error(resp.message)
        };
      }).catch(err=>{
        this.spinning = false;
        this.$message.error('同步失败', 2.5);
      })
    },

    reqEliminate(type, nbr, index) {
      let that = this;
      // 清空失效
      if (type == "1") {
        this.$commonPost({
          jydm: "flow_170005",
          flow_170005: {
            user_nbr:that.userInfo.nbr,
          },
        }).then(resp=>{
          if(resp.resp_code === "0"&& resp.resp_result==="0"){
            this.invalidList = [];
            message.success("已清空");
          }else{
            that.$message.error(resp.resp_msg);
          }
        }).catch(err=>{
          that.$message.error(err.message);
        })
      } 

      // 删除失效
      if (type == "2") {
        that.$commonPost({
          jydm: "flow_170003",
         flow_170003: {
          nbr:[nbr],
        },
        }).then(resp=>{
          if(resp.resp_code === "0" && resp.resp_result === "0"){
            this.invalidList.forEach((item) => {
              if (item.nbr == nbr) {
                this.invalidList.splice(index, 1);
                message.success("已删除");
              }
            });
          }else{
            that.$message.error(resp.resp_msg)
          }
        }).catch(err=>{
          that.$message.error(err.message)
        })
      } 

      // 删除正常商品 that.arrList
      if(type == "0") {
        that.$commonPost({
          jydm: "flow_170003",
         flow_170003: {
          nbr:[nbr],
        },
        }).then(resp=>{
          if(resp.resp_code === "0" && resp.resp_result === "0"){
            this.list.forEach((item) => {
              if (item.nbr == nbr) {
                this.list.splice(index, 1);
                message.success("已删除");
              }
            });
          }else{
            that.$message.error(resp.resp_msg)
          }
        }).catch(err=>{
          that.$message.error(err.message)
        })
      }

      // 删除正常商品 
      if(type == "3") {
        that.$commonPost({
          jydm: "flow_170003",
         flow_170003: {
          nbr:that.arrList,
        },
        }).then(resp=>{
          if(resp.resp_code === "0" && resp.resp_result === "0"){
            this.list.forEach((item) => {
              if (item.nbr == nbr) {
                this.list.splice(index, 1);
                message.success("已删除");
              }
            });
            that.reqCartList();
          }else{
            that.$message.error(resp.resp_msg)
          }
        }).catch(err=>{
          that.$message.error(err.message)
        });
      }
    },

    // 单商品勾选
    setCheckedType(nbr) {
      let that = this;
      this.list.forEach((item,index) => {
        if (item.nbr == nbr) {
          item.checked = !item.checked;
          this.$forceUpdate();
        }
      });

      if (this.list.findIndex((item) => item.checked === false) == -1) {
        this.indeterminate = true;
      } else {
        this.indeterminate = false;
      }

      // this.cartSum = 0;
      // this.calculateSum();
      this.setCheckData();
    },

    // 计算总和
    calculateSum() {
      this.cartSum = 0;
      this.list.map((item) => {
        if (item.checked) {
          this.cartSum += Number(item.price);
        }
      });
    },

    // 全选
    onCheckAllChange() {
      let that = this;
      this.indeterminate = !this.indeterminate;
      this.list.forEach((item, index) => {
        item.checked = that.indeterminate;
      });
      // this.cartSum = 0;
      // this.calculateSum();
      this.setCheckData();
    },

    // 存储选中数据
    setCheckData(){
      let that = this;
      that.checkData = [];
      that.list.forEach((item) => {
        if (item.checked == true) {
          that.checkData.push(item);
        }
      });
      that.arrList = [];
      that.checkData.forEach((items) => {
        if (items.checked == true) {
          that.arrList.push(items.nbr);
        }
      });
      console.log(that.arrList);
    },

    newArrFn () {
      let that = this;
      // 利用indexOf检测元素在数组中第一次出现的位置是否和元素现在的位置相等，
      // 如果相等，说明数组中没有重复的
      return Array.prototype.filter.call(that.checkData, function (item, index) { 
        return that.checkData.indexOf(item) === index
       })
    },

    // 购物车商品查询
    reqCartList(){
      let that = this;
      this.$commonPost({
        jydm: "flow_170002",
         flow_170002: {
          user_nbr:that.userInfo.nbr,
          type:'1',
          wechat_version:"1",
          page_index:that.numPerPage * that.pageIndex,
          num_per_page:that.numPerPage,
        },
      }).then(resp=>{
        if(resp.resp_code === "0" && resp.resp_result === "0"){
          that.list = [];
          that.invalidList = [];
          let arr = [];
           if(resp.commodity){
            if(resp.commodity.length>0){
              arr = resp.commodity;
            }else{
              arr.push(resp.commodity);
            }
          }
          that.totalRows = Number(resp.total_rows);
          // 正常商品
          arr.map((item,index)=>{
            if(item.state == 1){
              that.list.push(item);
              // that.list[index].checked = false;
            }else {
              // 失效商品
              that.invalidList.push(item);
            }
          })
        }else{
          that.$message.error(resp.resp_msg);
        }
      }).catch(err=>{
          that.$message.error(err.message);
      })
    },

    // 购物车失效商品查询
    reqCartInvalidList(){
      let that = this;
      this.$commonPost({
        jydm: "flow_170002",
         flow_170002: {
          user_nbr:that.userInfo.nbr,
          type:'1',
          wechat_version:"1",
          page_index:that.numPerPage * that.pageIndex,
          num_per_page:that.numPerPage,
        },
      }).then(resp=>{
        if(resp.resp_code === "0"&& resp.resp_result==="0"){
          let arr = [];
           if(resp.commodity){
            if(resp.commodity.length>0){
              arr = resp.commodity;
            }else{
              arr.push(resp.commodity);
            }
          }
          if(item.state == 0){
            that.invalidList = arr;
          }
          that.invalidList.map((item,index)=>{
            that.invalidList[index].invalid = 1;
          })
        }else{
          that.$message.error(resp.resp_msg);
        }
      }).catch(err=>{
          that.$message.error(err.message);
      })
    },
  },
  activated(){
    if(this.$route.meta){
      this.reqCartList();
      // this.reqCartInvalidList();
    }
  },
  mounted() {},
  created() {
    this.reqCartList();
    // this.reqCartInvalidList();
  },
};
</script>
<style lang="less" scoped>
.shoppingCart {
  width: 100%;
  margin: 0 auto;
  background: #f0f0f0;
  padding-bottom: 20px;
}
.pagination{
  background: #fff;
  width: 1190px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  z-index:9;
}
.shoppingListBox{
  // overflow: hidden;
  // height: auto;
  overflow-y: overlay;
  height: 75vh;
}
.shoppingCartBox {
  width: 1190px;
  margin: 0 auto;
  background: #ffffff;
  border-radius: 40px;
  margin-top: 10px;
  margin-bottom: 90px;
  .shoppingCartTop {
    width: 1190px;
    height: 76px;
    border-radius: 40px 40px 0 0;
    background: #ffffff;
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    z-index: 99;
    .cartTextBox {
      display: flex;
      align-items: center;
      .cartText {
        margin-left: 20px;
        font-size: 26px;
        color: #333333;
      }
      .cartSelectAll {
        margin-left: 20px;
        display: flex;
        align-items: center;
        img {
          width: 16px;
          height: 16px;
          margin-right: 10px;
        }
      }
      .cartEliminate {
        margin-left: 20px;
      }
    }
    .cartBtn {
      display: flex;
      margin-right: 20px;
      align-items: center;
      .sumPrice {
        font-size: 18px;
        color: #333333;
        margin-right: 20px;
        span {
          font-size: 18px;
          color: #ff0000;
        }
      }
      .synchronization {
        cursor: pointer;
        background: #ff0000;
        border-radius: 20px;
        width: 200px;
        height: 40px;
        font-size: 16px;
        color: #ffffff;
        line-height: 40px;
        text-align: center;
      }
    }
  }
}
</style>
<style scoped>
</style>