<template>
  <div class="userRecord">
    <div style="height: 64px; width: 100%"></div>
    <div class="userRecordBox">
            <!-- 表格 -->
      <div class="formBox">
        <!--  @change="onPaging" -->
        <a-table :columns="columns" :data-source="data" size="default" :pagination="paginationOpt">
           <!-- 提现方式 -->
           <span slot="type" slot-scope="text, record">
            <div>{{ `${record.type==1?"支付宝账户":record.type==2?"余额账户":
            record.type==3?"微信账户":record.type==4?"银行卡账户" : '暂无'}`}}</div>
          </span>
          <!-- 提现账户 -->
          <span slot="real_name" slot-scope="text, record" align="left">
            <div>名称：{{record.real_name || '暂无'}}</div>
            <div>账户：{{record.cashacc || '暂无'}}</div>
          </span>
          <!-- 申请日期 -->
          <span slot="create_time" slot-scope="text, record">
            <div>{{ record.create_time || "暂无"}}</div>
          </span>
          <!-- 提现状态 -->
          <span slot="state" slot-scope="text, record">
            <div>{{ `${record.state==1?"待审核":record.state==2?"审核通过":record.state==3?"拒绝":"暂无"}`}}</div>
          </span>
          <!-- 提现日期 -->
           <span slot="update_time" slot-scope="text, record">
            <div>{{ record.state==2? record.update_time :"暂无"}}</div>
          </span>
          <!-- 理由 -->
           <span slot="result" slot-scope="text, record">
            <div>{{ record.result || "暂无"}}</div>
          </span>
        </a-table>
      </div>
    </div>
  </div>
</template>
 
<script>
// 默认语言为 en-US，如果你需要设置其他语言，推荐在入口文件全局设置 locale
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
const columns = [
  {
    title: '提现方式',
    dataIndex: 'type',
    key: 'type',
    slots: { title: 'customTitle' },
    scopedSlots: { customRender: 'type' },
    align:"center",
  },
  {
    title: '提现账户',
    dataIndex: 'real_name',
    key: 'real_name',
    slots: { title: 'customTitle' },
    scopedSlots: { customRender: 'real_name' },
    align:"center",
  },
  {
    title: '提现金额',
    key: 'amount',
    dataIndex: 'amount',
    align:"center",
  },
  {
    title: '提现状态',
    dataIndex: 'state',
    key: 'state',
    slots: { title: 'customTitle' },
    scopedSlots: { customRender: 'state' },
    align:"center",
  },
  {
    title: '申请日期',
    dataIndex: 'create_time',
    key: 'create_time',
    align:"center",
  },
  {
    title: '提现日期',
    key: 'update_time',
    dataIndex: 'update_time',
    slots: { title: 'customTitle' },
    scopedSlots: { customRender: 'update_time' },
    align:"center",
  },
  {
    title: '理由',
    key: 'result',
    slots: { title: 'customTitle' },
    scopedSlots: { customRender: 'result' },
    align:"center",
    width:340,
  },
];
const data = [
  // {
  //   key: '1',
  //   number: '提现',
  //   dateTime: '1975-06-03 20:20:11',
  //   type: '拒绝',
  //   totalPrice: '300000',
  //   dateTime1: '1975-06-03 20:20:11',
  //   reason:"支付宝账号填写错误"
  // },
  // {key: "75", amount: "30.00", create_time: "2022-07-27 12:32:34",update_time: "2022-07-27 12:32:34", type: "1",state:'1',result:''}
];
import {mapGetters} from "vuex"
export default {
  name: "UserRecord",
  components: {},
  data() {
    return {
      paginationOpt: {
        defaultCurrent: 1, // 默认当前页数
        defaultPageSize: 10, // 默认当前页显示数据的大小
        total: 0, // 总数，必须先有
        // 改变每页数量时更新显示
        onChange: (current, size) => {
          this.paginationOpt.defaultCurrent = current-1;
          this.paginationOpt.defaultPageSize = size;
          this.pageIndex = current-1;
          this.numPerPage = size;
          this.reqCashList();
        },
      },
      data,
      columns,
      locale,
      pageIndex:0,
      numPerPage:10,
    };
  },
  computed:{
    ...mapGetters(["userInfo"])
  },
  methods: {
    // 获取提现数据
    reqCashList(){
      let that = this;
      this.$commonPost({
        jydm: "flow_60007",
        flow_60007: {
          user_nbr:that.userInfo.nbr,
          page_index:that.numPerPage * that.pageIndex,
          num_per_page:that.numPerPage,
        },
      }).then(resp=>{
        if(resp.resp_code == "0" && resp.resp_result == "0"){
          that.paginationOpt.total = Number(resp.total_rows) || "0";
          if(resp.record){
            let arr = [];
            if(resp.record.length>0){
              arr = resp.record;
            }else{
              arr.push(resp.record)
            }
            that.data = arr;
            that.data.map(item=>{
              item.key = item.nbr;
            })
          }
        }else{
          that.$message.error(resp.resp_msg)
        }
      }).catch(err=>{that.$message.error(err.message)})
    },
  },
  mounted() {},
  created() {
    document.title = "提现记录";
    this.reqCashList();
  },
};
</script>
<style lang="less" scoped>
.userRecord {
  width: 100%;
  margin: 0 auto;
  background: #f0f0f0;
  padding-bottom: 20px;
}
.userRecordBox {
  width: 1190px;
  margin: 0 auto;
}
.formBox{
  background: #fff;
  margin-top: 10px;
  font-size: 12px;
  .viewOrder{
    width: 72px;
    height: 15px;
    padding-right: 10px;
    border-right: 1px #D8D8D8 solid;
  }
}
</style>
<style scoped>
.ant-pagination {
      display: block;
}
.ant-table-wrapper >>> .ant-table-pagination.ant-pagination{
    margin: 16px 0;
    float: none !important;
    text-align: center;
}
</style>
