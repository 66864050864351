<template>
  <div class="commodityDetail">
    <div style="height: 64px; width: 100%"></div>
    <div class="detailBox">
      <div class="detailTop">
        <div class="detailTopImg" v-if="mediaNews.length>0">
            <a-carousel arrows>
              <div slot="prevArrow" class="custom-slick-arrow" style="left: 10px;zIndex: 1"><a-icon type="left-circle" /></div>
              <div slot="nextArrow" class="custom-slick-arrow" style="right: 10px"><a-icon type="right-circle" /></div>
              <div class="autoplay" v-for="(item,index) in mediaNews" :key="index">
                <video autoplay=false v-if="item.type==1" style="width: 446px; height: 446px;" :poster="item.img" 
                :src="item.url" autopsy controls class="multimedia"></video>
                <img style="width: 446px; height: 446px;" v-else :src="item.url" class="multimedia">
              </div>
            </a-carousel>
        </div>

        <div class="detailMess">
          <div class="detailText">{{teamData.commodityName}}</div>
          <div class="detailPrice">￥{{teamData.minSellPrice}}</div>

          <div class="detailNormsBox" v-for="(item,index) in teamData.propertyJson" :key="index">
            <div class="normsText">{{item.name}}:</div>
            <div class="normsMessBox" v-for="(items,index) in item.children" :key="index">
              <div class="normsMess">{{items.name}}</div>
            </div>
          </div>

          <div class="salesVolume" style="margin-top: 50px;">销量：{{teamData.salesNum}}</div>
          <div class="salesVolume">库存：{{teamData.totalQuantity}}</div>

          <div class="btn" @click="oneClickOption">加入一键选品购物车</div>
          <div class="collection">
            <span>收藏：</span>
            <img v-if="collection == 1"  @click="reqCollection" class="collectionImg1" src="../commodityDetail/img/xuanzhong.png" alt="">
            <img v-else class="collectionImg2"  @click="reqCollection" src="../commodityDetail/img/weixuanzhong.png" alt="">
          </div>
        </div>
      </div>
    </div>

    <!-- 商品运费 -->
    <div class="freightBox">
      <div class="freightTop">商品运费</div>
      <div class="freightMiddle">
        <table>
          <tr>
            <th style="width: 514px;">可配送区域</th>
            <th style="width: 252px;">首件(个)</th>
            <th style="width: 198px;">运费(元)</th>
            <th style="width: 134px;">续件(个)</th>
            <th style="width: 134px;">续费(元)</th>
          </tr>
          <tr class="myStyle" :class="index % 2 == 0 ? 'myStyle1 myStyle2' : 'myStyle myStyle2'" v-for="(item,index) in data" :key="item.key">
            <td>{{item.link_name}}</td>
            <td>{{item.first_quantity}}</td>
            <td>{{item.amount}}</td>
            <td>{{item.renewal_quantity}}</td>
            <td>{{item.renewal_amount}}</td>
          </tr>
        </table>
      </div>
    </div>

    <!-- 商品素材 -->
    <div class="freightBox" v-if="sourceList.length>0">
      <div class="freightTop">商品素材</div>
      <div class="freightMiddle" v-for="(item,index) in  sourceList" :key="index">
        <div class="materialBox">
          <img class="materialImg" :src="item.user_head" alt="">
          <span>{{item.user_nickname}}</span>
        </div>
        <div class="materialBox1">
          <span>{{item.content}}</span>
        </div>
        <div class="materialExhibition">
          <video id="media" class="media" v-if="item.comment_video" height="100" width="100" :poster="item.comment_video_cover"
          style="object-fit:fill" controls :src="item.comment_video"></video>
          <div v-for="(items,index) in item.sourceImg" :key="index">
              <img class="materialExhibitionImg" :src="items" alt="">
          </div>

        </div>
      </div>
    </div>

    <!-- 商品详情 -->
    <div class="freightBox">
      <div class="freightTop">商品详情</div>
      <div class="freightMiddle">
        <div class="materialBox1">
          <div class="materialDesc" v-html="teamData.commodityDesc"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { handleText } from "../../assets/js/tool";
export default {
  name: "CommodityDetail",
  components: {},
  computed: {
    ...mapGetters(["userInfo"]),
  },
  data() {
    return {
      imgDisplay:true,
      collection:0,
      teamData: {},
      data:[],
      sourceList:[],
      sourceImg:[],
      isCommNbr:'',

      mediaLastIndex: 0,
      mediaNews: [
        // 1为视频类2为图片类
        // { url: './assets/v1.jpg', type: 2 },
        // { url: './assets/v1.mp4', type: 1 },
      ]
    }
  },
  watch: {
    mediaNews: {
      handler(newName, oldName) {
        this.initOrNot = false
        this.$nextTick(() => {
          this.initOrNot = true
        })
      },
      immediate: true,
      deep: true
    }
  },
  methods: {

    reqCollection(){
      if(this.userInfo && this.userInfo.nbr == ""){
        return this.$message.warning('您还未登录，登录后即可收藏');
      }else{
        if(this.collection == 0){
          this.collection = 1;
          this.collect();
        } else{
          this.collection = 0;
          this.callCollect();
        }
      }
    },

    // 加入选品购物车
    oneClickOption(){
      if(this.userInfo && this.userInfo.nbr == ""){
        return this.$message.warning('您还未登录，登录后即可选品');
      }else{
        let that = this;
        this.$commonPost({
          jydm: "flow_170001",
          flow_170001: {
            commodity_nbr:that.teamData.nbr,
            user_nbr:that.userInfo.nbr,
            quantity:"1",
          },
        }).then(resp=>{
          if(resp.resp_code === "0"&& resp.resp_result==="0"){
            that.$message.success('选品'+resp.resp_msg);
          }
        }).catch(err=>{
          that.$message.error(err.message);
        })
      }
    },

    // 收藏 
    collect(){
      let that = this;
      this.$commonPost({
        jydm: "flow_10012",
        flow_10012: {
          commodity_nbr:that.teamData.nbr,
          user_nbr:that.userInfo.nbr,
        },
      }).then(resp=>{
          if(resp.resp_code === "0"&& resp.resp_result==="0"){
            that.$message.success(resp.resp_msg);
          } else {
            that.$message.error(resp.resp_msg);
          }
      }).catch((err) => {
        that.$message.error(err.message);
      });
    },

    // 取消收藏 
    callCollect(){
      let that = this;
      let commNbr = {nbr:[`${that.teamData.nbr}`]};
      this.$commonPost({
        jydm: "flow_10013",
         flow_10013: {
          commodity:commNbr,
          user_nbr:that.userInfo.nbr,
        },
      }).then(resp=>{
          if(resp.resp_code === "0"&& resp.resp_result==="0"){
             that.$message.success(resp.resp_msg);
          } else {
            that.$message.error(resp.resp_msg);
          }
      }).catch((err) => {
        that.$message.error(err.message);
      });
    },

    // 查询收藏type 
    reqCollectType(nbr){
      let that = this;
      this.$commonPost({
        jydm: "flow_10015",
         flow_10015: {
          commodity_nbr:nbr,
          user_nbr:that.userInfo.nbr,
        },
      }).then(resp=>{
          if(resp.resp_code === "0"&& resp.resp_result==="0"){
            resp.collection_state == 0 ? that.collection = 0 : that.collection = 1;
          } else {
            that.$message.error(resp.resp_msg);
          }
      }).catch((err) => {
        that.$message.error(err.message);
      });
    },

    // 查询素材列表/详情 70103
    materialList(nbr){
      let that = this;
      this.$commonPost({
        jydm: "flow_70103",
         flow_70103: {
          commodity_nbr:nbr,
          page_index:0,
          num_per_page:10,
        },
      }).then(resp=>{
          if(resp.resp_code === "0"&& resp.resp_result==="0"){
            let arr = [];
            if(resp.list){
              if(resp.list.length>0){
                arr = resp.list;
              } else {
                arr.push(resp.list);
              }
              that.sourceList = arr;
              that.sourceList.map((item,index)=>{
                that.sourceList[index].sourceImg=item.comment_image.split(',');
              })
            }
          } else {
            that.$message.error(resp.resp_msg);
          }
      }).catch((err) => {
        that.$message.error(err.message);
      });
    },

    // 商品详情运费模板70059
    reqTemplate(nbr){
      let that = this;
      this.$commonPost({
        jydm:"flow_70059",
        flow_70059:{
          nbr:nbr,
          wechat_version:'1',
        }
      }).then(resp=>{
        if(resp.resp_code==="0"&&resp.resp_result==="0"){
          let arr = [];
          if(resp.postage.length>0){
              arr = resp.postage;
          }else{
          arr.push(resp.postage);
          }
          that.data = arr;
        }
      }).catch(err=>{
        that.$message.error(err.message)
      })
    },

    reqCommMess(){
      let that = this;
      that.teamData = {},
      that.data = [],
      that.sourceList = [],
      that.sourceImg = [],
      that.$http.post("/api/customer/queryCommodityDetail", {
        commodityNbr: that.isCommNbr,
        merchantNbr: that.userInfo.nbr,
      }).then(resp=>{
        if (resp.data.code == "200") {
          const { commodity } = resp.data.object.resp;
          that.teamData = commodity;
          that.teamData.commodityDesc = handleText(that.teamData.commodityDesc);
          that.materialList(commodity.nbr);
          if(that.userInfo && that.userInfo.nbr != ""){
            that.reqCollectType(commodity.nbr);
          }
          that.reqTemplate(commodity.nbr);
          that.mediaNews = [];
          let arrList = [];
          // 视频
          if (commodity.commodityVideo) {
            let obj = {};
              obj.url = commodity.commodityVideo;
              obj.type = 1;
              obj.img = commodity.commodityVideoCover || commodity.commodityLogoList.split(",")[0];
              arrList.push(obj);
          }
          // 图片
          if (commodity.commodityLogoList) {
            let arr = commodity.commodityLogoList.split(",");
            arr.forEach(element => {
              let obj = {};
              obj.url = element;
              obj.type = 2;
              arrList.push(obj);
            });
          }
          that.mediaNews = arrList;
        }
      })
    }
  },
  mounted() {},
  activated(){
    if(this.$route.meta){
      this.isCommNbr = this.$route.query.commNbr;
      let isCommNbr1 = window.localStorage.getItem('orderNbr');
      if(this.isCommNbr != isCommNbr1 && this.isCommNbr != undefined){
        window.localStorage.setItem('orderNbr',this.isCommNbr);
        this.isCommNbr = window.localStorage.getItem('orderNbr');
      }else{
        this.isCommNbr = window.localStorage.getItem('orderNbr');
      }
      this.reqCommMess();
    }
  },
  created() {
  this.isCommNbr = this.$route.query.commNbr;
  // this.reqCommMess();
  },
};
</script>
<style lang="less" scoped>
.commodityDetail {
  width: 100%;
  margin: 0 auto;
  background: #fff;
  padding-bottom: 20px;
}
.detailBox {
  width: 1190px;
  margin: 0 auto;
}
.freightBox {
  width: 1190px;
  margin: 0 auto;
  margin-top: 20px;
  .freightTop{
    width: 100%;
    height: 54px;
    background: #FFFFFF;
    font-size: 22px;
    color: #3A3A3A;
    border: 1px solid #E2E2E2;
    line-height: 54px;
    padding-left: 12px;
    font-weight: bold;
  }
  .freightMiddle{
    width: 100%;
    // height: 54px;
    background: #FFFFFF;
    border: 1px solid #E2E2E2;
    margin-top: -1px;
      table{
      width: 100%;
      text-align: center;
      border: 0px;
      th{
        border-bottom:1px solid #DCDEE3;
        // background: #EBECF0;
        padding: 10px;
      }
      td{
        height: 120px;
        overflow: hidden;
        padding: 40px 20px;
        word-break : break-all;
      }
    }
    .myStyle2:hover{
      background: #F2F2F2;
    }
    .myStyle{
      background: #f8f8f8;
    }
    .myStyle1{
      background: #fff;
    }

  .materialBox{
    margin: 14px;
    height: 30px;
    .materialImg{
      width: 30px;
      height: 30px;
      margin-right: 14px;
    }
    span{
      font-size: 14px;
      color: #3A3A3A;
      line-height: 30px;
    }
  }

  .materialBox1{
    margin: 14px;
    .materialDesc {
      p {
        img {
          width: 100%;
        }
      }
    }
  }

  .materialExhibition{
    //  margin: 14px;
     display: flex;
    .materialExhibitionImg{
      width: 100px;
      height: 100px;
      border-radius: 6px;
       margin-left: 14px;
       margin-bottom: 14px;
    }
    .media{
    border-radius: 6px;
    margin-left: 14px;
    margin-bottom: 14px;
    }
  }
  }
}
.detailTop{
  width: 1169px;
  height: 446px;
  margin: 0 auto;
  margin-top:20px;
  display: flex;
  .detailTopImg{
    width: 446px;
    height: 446px;
    border-radius: 6px;
    .autoplay{
      width: 446px;
      height: 446px;
      border-radius: 6px;
    }
    .swiper-container{
      width: 446px;
      height: 446px;
      border-radius: 6px;
    }
  }
  .detailMess{
    width: 704px;
    height: 446px;
    margin-left:20px;
    .detailText{
      width: 704px;
      font-size: 22px;
      color: #333333;
      overflow: hidden;
      text-overflow: ellipsis;
      display:-webkit-box;
      -webkit-box-orient:vertical;
      -webkit-line-clamp:2; // 2行
    }
    .detailPrice{
      font-size: 28px;
      color: #FF0000;
      margin-top:12px;
    }
    .detailNormsBox{
      width: 704px;
      margin-top: 20px;
      display: flex;
      align-items: center;
       margin-bottom: 10px;
      // height: 32px;
      .normsText{
        font-size: 16px;
        color: #666666;
        margin-right: 10px;
      }
      .normsMessBox{
        display: flex;
        .normsMess{
          margin-right: 10px;
          padding: 5px 10px;
          background: #F0F0F0;
          border-radius: 4px;
        }
      }
    }
  }
  .salesVolume{
    font-size: 16px;
    color: #7D7D7D;
    margin-bottom: 10px;
  }
  .btn{
     cursor: pointer;
    line-height: 42px;
    text-align: center;
    width: 202px;
    height: 42px;
    background-image: linear-gradient(90deg, #FFCA01 0%, #FF9602 100%);
    border-radius: 21px;
    color: #fff;
  }
  .collection{
    cursor: pointer;
    position: relative;
    bottom: -75px;
  }
}
</style>

<style scoped>
.materialBox1 >>> img {
  width: 100%;
}

.ant-carousel >>> .slick-slide {
  text-align: center;
  height: 446px;
  line-height: 446px;
  overflow: hidden;
}

.ant-carousel >>> .custom-slick-arrow {
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: rgb(5, 5, 5);
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
}
.ant-carousel >>> .custom-slick-arrow:before {
  display: none;
}
.ant-carousel >>> .custom-slick-arrow:hover {
  opacity: 0.5;
}
</style>
