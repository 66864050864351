<template>
  <div>
    <div class="specialSession">
      <div class="specialTitleBox">
        <div class="specialTitle1">{{ areaTitle }}</div>
        <span class="specialTitle2" @click="goPrefecture(dataList.area_nbr)">查看更多</span>
      </div>
      <div class="specialSessionBannerBox" @click="goPrefecture(dataList.area_nbr)">
        <img :src="imagePath" />
      </div>
      <div class="specialSessionListBox">
        <div class="specialListBox">
          <div class="ListBox" v-for="(item,index) in CommoList" :key="index" @click="goDetail(item.nbr)">
            <img class="ListBoxImg" :src="item.commodity_logo" alt="" />
            <div>{{item.commodity_name}}</div>
            <div>￥{{item.min_sell_price}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "specialSession",
  props: {
    dataList: Object,
  },
  data(){
    return{
      CommoList:[],
      areaTitle:'',
      imagePath:'',
    }
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  methods:{
    goPrefecture(nbr){
      this.$router.push({
        path:'/prefectureList',
        query:{areaNbr:nbr}
      })
    },
    getSpecialCommo(){
      let that = this;
      that.$commonPost({
        jydm: "flow_70013",
        flow_70013: {
          area_nbr: that.dataList.area_nbr,
          user_nbr:that.userInfo.nbr,
          type:'-1',
          page_index:0,
          num_per_page:that.dataList.show_num,
        },
      }).then(resp => {
        if(resp.resp_code==0 && resp.resp_result==0){
          that.areaTitle = resp.area_title;
          that.imagePath = resp.image_path;
          let arr = [];
          if(resp.commodity.length>0){
            arr = resp.commodity
          }else{
            arr.push(resp.commodity)
          }
          that.CommoList = arr;
        } else {
          that.$message.error(resp.resp_msg)
        }
      })
      .catch((err) => {
        that.$message.error(err.message)
      });
    },
    goDetail(nbr){
      this.$router.push({
        path:'/commodityDetail',
        query:{ commNbr:nbr }
      })
    },
  },
  created() {
    this.getSpecialCommo();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.specialSession {
  background: #fff;
  border-radius: 6px;
  padding-bottom: 10px;
  .specialTitleBox {
    display: flex;
    justify-content: space-between;
    // height: 54px;
    padding: 10px 0 10px 10px;
    border-bottom: 1px #f3f3f3 solid;
    margin-bottom: 10px;
    // .specialTitle1 {
    //   padding: 6px 10px 6px 10px;
    //   background: #ff2727;
    //   border-radius: 17px;
    //   text-align: center;
    //   font-size: 16px;
    //   color: #ffffff;
    // }
    .specialTitle1 {
      // padding: 6px 10px 6px 10px;
      // background: #ff2727;
      // border-radius: 17px;
      // text-align: center;
      // font-size: 16px;
      // color: #ffffff;
        padding: 0 10px;
        background: #ff2727;
        border-radius: 17px;
        text-align: center;
        font-size: 16px;
        line-height: 35px;
        color: #fff;
    }
    .specialTitle2 {
      cursor: pointer;
      line-height: 35px;
      padding-left: 10px;
      padding-right: 10px;
      border-radius: 17px;
      text-align: center;
      font-size: 16px;
      color: #999;
      margin-right:10px;
    }
  }
  .specialSessionBannerBox {
    cursor: pointer;
    width: 100%;
    height: 260px;
    border-radius: 6px;
    margin: 10px 0 10px 0;
    img {
      margin: 0 10px 0 10px;
      width: 1170px;
      height: 260px;
    }
  }
  .specialSessionListBox {
    width: 100%;
    margin: 10px 0 10px 0;
    .specialListBox {
      display: flex;
      flex-wrap: wrap;
      margin: 0 0px 0 10px;
      .ListBox {
        cursor: pointer;
        margin-right: 18px;
        width: 218px;
        // height: 285px;
        margin-bottom: 20px;
        .ListBoxImg {
          width: 218px;
          height: 218px;
          border-radius: 6px;
        }
        :nth-child(2) {
          font-size: 16px;
          color: #333333;
          margin-top: 10px;
          text-overflow:-o-ellipsis-lastline;
          overflow:hidden; 
          text-overflow: ellipsis; 
          display: -webkit-box; 
          -webkit-line-clamp: 2; 
          -webkit-box-orient: vertical;
        }
        :nth-child(3) {
          font-size: 18px;
          color: #ff0000;
          margin-top: 10px;
        }
      }
    }
  }
}
</style>
