import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/login/Login.vue'// 登录

import Home from '../views/home/Home.vue' //首页
import ShoppingCart from '../views/cart/ShoppingCart.vue' //购物车
import Management from '../views/management/Management.vue' //资金管理
import ResultOrder from '../views/resultOrder/ResultOrder.vue' // 结算订单
import CommodityPoints from '../views/commodityPoints/CommodityPoints.vue' // 商品分类
import UserRecord from '../views/userRecord/UserRecord.vue' // 提现记录
import MyChoice from '../views/myChoice/MyChoice.vue' // 我的选品
import CommCollection from '../views/commCollection/CommCollection.vue' // 商品收藏
import CommStatistics from '../views/commStatistics/CommStatistics.vue' // 佣金订单 

import CommodityDetail from '../views/commodityDetail/CommodityDetail.vue' // 商品详情 
import OrderDetail from '../views/orderDetail/OrderDetail.vue' // 订单详情 
import OrderCommodity from '../views/orderCommodity/OrderCommodity.vue' // 订单商品
import SelectPayment from '../views/selectPayment/SelectPayment.vue' // 选择支付 
import PaymentStatus from '../views/paymentStatus/PaymentStatus.vue' // 支付状态 
import PrefectureList from '../views/prefectureList/PrefectureList.vue' // 专区列表
import QueryCommodity from '../views/queryCommodity/QueryCommodity.vue' // 搜索商品 

import Tabbar from '../components/tabbar.vue'
const routerPush = VueRouter.prototype.push;

VueRouter.prototype.push = function (location) {
  return routerPush.call(this, location).catch(err => { })
};

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/tabbar',
  },
  {
    path: '/tabbar',
    name: 'tabbar',
    component: Tabbar,
    redirect: '/home',
    children: [{
      path: '/home',
      name: 'Home',
      component: Home,
      meta: {
        keepAlive: true, //此组件需要被缓存
      }
    },
    {
      path: '/shoppingCart',
      name: 'ShoppingCart',
      component: ShoppingCart,
      meta: {
        keepAlive: true, //此组件需要被缓存
      }
    },
    {
      path: '/management',
      name: 'Management',
      component: Management
    },
    {
      path: '/resultOrder',
      name: 'ResultOrder',
      component: ResultOrder
    },
    {
      path: '/commodityPoints',
      name: 'CommodityPoints',
      component: CommodityPoints
    },
    {
      path: '/userRecord',
      name: 'UserRecord',
      component: UserRecord
    },
    {
      path: '/myChoice',
      name: 'MyChoice',
      component: MyChoice
    },
    {
      path: '/commCollection',
      name: 'CommCollection',
      component: CommCollection
    },
    {
      path: '/commStatistics',
      name: 'CommStatistics',
      component: CommStatistics
    },
    {
      path: '/commodityDetail',
      name: 'CommodityDetail',
      component: CommodityDetail
    }, 
    {
      path: '/orderCommodity',
      name: 'OrderCommodity',
      component: OrderCommodity
    }, 
    {
      path: '/orderDetail',
      name: 'OrderDetail',
      component: OrderDetail
    },
    {
      path:'/selectPayment',
      name:'SelectPayment',
      component:SelectPayment,
      meta: {
        keepAlive: true, //此组件需要被缓存
      }
    },
    {
      path:'/paymentStatus',
      name:'PaymentStatus',
      component:PaymentStatus
    },
    {
      path:'/PrefectureList',
      name:'PrefectureList',
      component:PrefectureList
    },
    {
      path:'/QueryCommodity',
      name:'QueryCommodity',
      component:QueryCommodity
    },
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

export default router
