<template>
  <div id="tabbar">
    <!--使用keep-alive会将页面缓存-->
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
    <div class="bottomBar">
      <ul>
        <div class="box">
          <router-link :to="{ name: 'Home' }">
            <li @click="toColor">
              <p :class="{ bottomBarColorActive: isColor }">首页</p>
            </li>
          </router-link>

          <router-link @click.native="toColor2" to>
            <li>
              <p :class="{ bottomBarColorActive: isColor2 }">购物车</p>
            </li>
          </router-link>

          <router-link @click.native="toColor3" to>
            <li>
              <p :class="{ bottomBarColorActive: isColor3 }">结算订单</p>
            </li>
          </router-link>

          <router-link @click.native="toColor4" to>
            <li>
              <p :class="{ bottomBarColorActive: isColor4 }">资金管理</p>
            </li>
          </router-link>

          <router-link @click.native="toColor5" to>
            <li>
              <p :class="{ bottomBarColorActive: isColor5 }">商品分类</p>
            </li>
          </router-link>
        </div>
        <router-link @click.native="toColor6" to>
          <li>
            <p :class="{ bottomBarColorActive: isColor6 }">{{textLogin}}</p>
          </li>
        </router-link>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters ,mapMutations} from "vuex";
export default {
  name: "Tabbar",
  data() {
    return {
      isColor: false,
      isColor2: false,
      isColor3: false,
      isColor4: false,
      isColor5: false,
      isColor6: false,

      current_tab: "",
      isLogin: false,
      textLogin:"去登录"
    };
  },
  watch: {
    userInfo: {
        handler(newName, oldName) {
          if(newName.nbr == '') this.textLogin = '去登录';
          else this.textLogin = '退出登录';
        },
        immediate: true,
        deep: true
    },
    
    $route(to, form) {
      if (to.name == "Home") {
        document.title = "首页";
        this.isColor = true;
        this.isColor2 = false;
        this.isColor3 = false;
        this.isColor4 = false;
        this.isColor5= false;
      } else if (to.name == "Management") {
        document.title = "资金管理";
        this.isColor4 = true;
        this.isColor3 = false;
        this.isColor2 = false;
        this.isColor = false;
        this.isColor5= false;
      } else if (to.name == "ShoppingCart") {
        document.title = "购物车";
        this.isColor2 = true;
        this.isColor3 = false;
        this.isColor4 = false;
        this.isColor = false;
        this.isColor5= false;
      } else if (to.name == "ResultOrder") {
        document.title = "结算订单";
        this.isColor2 = false;
        this.isColor3 = true;
        this.isColor4 = false;
        this.isColor = false;
        this.isColor5= false;
      }
      else if (to.name == "CommodityPoints") {
        document.title = "商品分类";
        this.isColor2 = false;
        this.isColor3 = false;
        this.isColor4 = false;
        this.isColor = false;
        this.isColor5= true;
      }
    },
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  methods: {
    ...mapMutations({
      senduserInfo: "SENDuserInfo",
    }),
    toColor: function () {
      this.isColor = true;
      this.isColor2 = false;
      this.isColor4 = false;
      this.isColor3 = false;
      this.isColor5 = false;
    },
    toColor2: function () {
      if ((this.userInfo && this.userInfo.nbr == "")) {
        return this.$message.warning('您还未登录，登录后可查看');
      }
      this.$router.push({
        path: "/shoppingCart",
      });
      this.isColor = false;
      this.isColor4 = false;
      this.isColor2 = true;
      this.isColor3 = false;
      this.isColor5 = false;
    },
      toColor3: function () {
      if ((this.userInfo && this.userInfo.nbr == "")) {
        return this.$message.warning('您还未登录，登录后可查看');
      }
      this.$router.push({
        path: "/resultOrder",
      });
      this.isColor = false;
      this.isColor2 = false;
      this.isColor3 = true;
      this.isColor4 = false;
      this.isColor5 = false;
    },
    toColor4: function () {
      if ((this.userInfo && this.userInfo.nbr == "")) {
        return this.$message.warning('您还未登录，登录后可查看');
      }
      this.$router.push({
        path: "/management",
        // query: { supNbr: this.userInfo.nbr },
      });
      this.isColor = false;
      this.isColor2 = false;
      this.isColor3 = false;
      this.isColor5 = false;
      this.isColor4 = true;
    },
    toColor5: function () {
      // if (!(this.userInfo && this.userInfo.nbr)) {
      //   return this.$router.push({
      //     path: "/login",
      //   });
      // }
      this.$router.push({
        path: "/commodityPoints",
      });
      this.isColor = false;
      this.isColor2 = false;
      this.isColor3 = false;
      this.isColor5 = true;
      this.isColor4 = false;
    },
    toColor6: function () {
      if ((this.userInfo && this.userInfo.nbr == "")) {
        this.textLogin = '去登录';
        return this.$router.push({
          path: "/login",
        });
      }else{
        this.textLogin = '退出登录';
        let userInfo = {
          nbr: "",
        };
        this.senduserInfo(userInfo);
        window.scrollTo(0, 0);
        localStorage.removeItem("userInfo");
        this.$router.push({path: "/login" });
      }
    },
  },

  mounted() {
    this.current_tab = this.$router.history.current.name;
    var name = this.current_tab;
    if (name == "Home") {
      this.isColor = true;
    } else if (name == "ShoppingCart") {
      this.isColor2 = true;
    } else if (name == "ResultOrder") {
      this.isColor3 = true;
    } else if (name == "Management") {
      this.isColor4 = true;
    }else if (name == "CommodityPoints") {
      this.isColor5 = true;
    }
  },
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}
.bottomBar {
  height: 64px;
  width: 100%;
  background: #ff8e47;
  position: fixed;
  top: 0;
  z-index: 100;
  margin: 0 auto;
}

.bottomBar ul {
  display: flex;
  width: 1190px;
  height: 100%;
  margin: 0 auto;
  justify-content: space-between;
}
.bottomBar ul .box {
  display: flex;
  width: 480px;
  height: 100%;
}
.bottomBar ul li {
  width: 96px;
  flex: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.bottomBar ul li p {
  font-family: 400;
  font-size: 14px;
  color: #fff;
  letter-spacing: 0;
  line-height: 14px;
  margin-bottom: 0;
}
.bottomBarColorActive {
  width: 96px;
  flex: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: rgba(255, 18, 18, 0.2) !important;
}
</style>
