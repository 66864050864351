<template>
  <div class="home">
    <div style="height: 64px; width: 100%; bottom: 100px;"></div>

    <a-button type="circle" @click="isTarget" style="font-size: 32px;
    top: 760px; width: 52px; height: 52px;position: absolute; 
    right: 40px;" icon="caret-up"></a-button>

    <div class="homeBox">
      <!-- 搜索 -->
      <div class="searchBox">
        <div class="searchFrameBox" @click="goPage('0')">
          <div class="searchFrame">请点击搜索框进行搜索</div>
          <div class="searchFrameBtn">搜索</div>
        </div>
      </div>

      <!-- 轮播 个人信息 -->
      <div class="personalBox">
        <!-- 轮播 -->
        <div class="bannerBox">
          <a-carousel effect="fade" autoplay arrows :after-change="onChange" v-if="lunboList.length > 0">
            <div slot="prevArrow" class="custom-slick-arrow" style="left: 10px;zIndex: 1"><a-icon type="left-circle" /></div>
            <div slot="nextArrow"  class="custom-slick-arrow" style="right: 10px"><a-icon type="right-circle" /></div>
            <div class="swiper-slide" @click="reqBannerGo(lunbo.type,lunbo.extra)" v-for="(lunbo, i) in lunboList" :key="i">
              <img :src="lunbo.image_path" alt />
            </div>
          </a-carousel>
          <div style="text-align: center;line-height: 384px;color:#999;">暂无轮播图</div>
        </div>
        <!-- 个人信息 -->
        <div class="informationBox">
          <!-- 头像昵称 -->
          <div class="infoHeadBox">
            <img class="infoHead" src="../home/img/defaultAvatar.png" alt="" srcset="">
            <span class="infoName">{{info.nick_name || "暂无"}}</span>
          </div>

          <!-- 账户 -->
          <div class="commissionAccountBox">
            <div class="commissionBox">
              <div class="commissionNum">{{info.pending_amount ||0.00}}</div>
              <div class="commissionText">待结算佣金</div>
            </div>
            <div class="commissionBox">
              <div class="commissionNum">{{info.brokerage || 0.00}}</div>
              <div class="commissionText">已结算佣金</div>
            </div>
            <div class="commissionBox">
              <div class="commissionNum">{{info.equity || 0.00}}</div>
              <div class="commissionText">股权账户</div>
            </div>
          </div>

          <!-- 功能入口 -->
          <div class="functionEntranceBox">
            <div class="functionBox"  @click="goPage('2')">
              <img class="functionImg" src="../home/img/zijin.png">
              <div class="functionText">资金管理</div>
            </div>
            <div class="functionBox" @click="goPage('1')">
              <img class="functionImg" src="../home/img/xp.png">
              <div class="functionText">我的选品</div>
            </div>
            <div class="functionBox" @click="goPage('3')">
              <img class="functionImg" src="../home/img/gwc.png">
              <div class="functionText">购物车</div>
            </div>
            <div class="functionBox" @click="goPage('4')">
              <img class="functionImg" src="../home/img/sc.png">
              <div class="functionText">商品收藏</div>
            </div>
          </div>

           <!-- 基础信息 -->
          <div class="basicIntelBox">
            <div class="basicBox">
              <div class="basicText">手机号码：{{info.phone_no || "暂无"}}</div>
              <div class="basicText">注册时间：{{info.create_time || "暂无"}}</div>
            <div class="basicText">商户性别：{{info.sex == 0 ? '女' : info.sex == 1 ? '男' : "暂无"}}</div>
            </div>
            <div class="basicBox">
              <div class="basicText">商户编号：{{userInfo.nbr||"暂无"}}</div>
              <div class="basicText">生日日期：{{info.birth_day||"暂无"}}</div>
            </div>
          </div>

           <!-- 编辑按钮 -->
          <div class="editButton" @click="reqEditButton">编辑按钮</div>
        </div>
      </div>

      <a-modal title="编辑信息" :visible="visible" cancelText="取消" okText="确认修改" @ok="handleOk" @cancel="handleCancel">
      <div class="modifyBox">
        <div class="modify">
          <div>请选择生日：</div>
          <a-date-picker :default-value="moment(info.birth_day)" :value="moment(info.birth_day)" 
          :inputReadOnly="true" @change="onChangeDate" :locale="locale"/>
        </div>
        <div class="modify">
          <div>请选择性别：</div>
          <a-radio-group name="radioGroup" :default-value="Number(info.sex)" @change="onChangeSet">
            <a-radio :value="1">男</a-radio><a-radio :value="0">女</a-radio>
          </a-radio-group>
        </div>
      </div>
      </a-modal>

      <!-- 专场 -->
      <div class="specialSessionBox" v-for="areaItem in areaList" :key="areaItem.nbr">
        <SpecialSession :dataList="areaItem"/>
      </div>

      <!-- 普通商品 -->
      <div class="specialSession" v-if="allInCommList.length>0">
        <div class="specialTitleBox">
          <div class="specialTitle1">{{ "推荐商品" }}</div>
        </div>
        <div class="specialSessionListBox">
          <div class="specialListBox">
            <div class="ListBox" v-for="(item,index) in allInCommList" :key="index" @click="goDetail(item.nbr)">
              <img class="ListBoxImg" :src="item.commodity_logo" alt="" />
              <div>{{item.commodity_name}}</div>
              <div>￥{{item.sell_price}}</div>
            </div>
          </div>
        </div>
        <!-- <div style="height: 64px; width: 100%"></div> -->
        <!-- 分页 -->
        <div class="pagination">
          <a-pagination href="javascript:;" v-model="current"  @change="onPaging" :total="totalNum" show-less-items/>
        </div>
      </div>
    </div>
  </div>
</template>
 
<script>
import moment from "moment"
import 'moment/locale/zh-cn';
import SpecialSession from "../../components/specialSession.vue"
 import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import { message } from 'ant-design-vue';
import { mapGetters } from "vuex";
export default {
  name: "Home",
  components: {
    SpecialSession
  },
  data() {
    return {
      current:1,
      areaList:[],
      visible:false,
      locale,
      setValue:"1",
      info:"",
      bannerIndex:0,
      lunboList: [],
      list:[],
      dateString:'',
      numPerPage:10,
      pageIndex:0,
      totalNum:0,
      allInCommList:[]
    };
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  methods: {
    moment,

    isTarget(){
      return document.querySelector('#app').scrollTop = 0;
    },

    showModal() {
      this.visible = true;
    },
    handleOk(e) {
      if(!this.setValue || !this.dateString) return message.error("修改内容不能为空！")
      this.visible = false;
      let that = this;
      this.$commonPost({
         jydm: "flow_10028",
        flow_10028: {
          user_nbr:that.userInfo.nbr,
          birth_day:that.dateString,
          sex:that.setValue,
        },
      }).then(resp=>{
        if (resp.resp_code == 0 && resp.resp_result == 0) {
          that.$message.success("修改"+resp.resp_msg)
          // 个人信息接口
          if(that.userInfo && that.userInfo.nbr){
            this.reqGetData();
          } else {
            that.$router.push({
              path:'/login'
            })
          }
        }
      })
      .catch(err=>{
        that.$message.error(err.message);
      })
    },

    goDetail(nbr){
      this.$router.push({
        path:'/commodityDetail',
        query:{commNbr:nbr}
      })
    },

    handleCancel(e) {
      this.visible = false;
    },

    goPage(type){
      if(this.userInfo && this.userInfo.nbr == ""){
        return this.$message.warning('您还未登录，登录后即可查看');
      }else{
        // 搜索
        if(type == 0){
          this.$router.push({
            path:'/QueryCommodity',
          })
        }
        
        // 我的选品
        if(type == 1){
          this.$router.push({
            path:'/MyChoice',
          })
        }
        // 资金管理
        if(type == 2){
          this.$router.push({
            path:'/Management',
          })
        }
        // 购物车
        if(type == 3){
          this.$router.push({
            path:'/shoppingCart',
          })
        }
        // 商品收藏
        if(type == 4){
          this.$router.push({
            path:'/commCollection',
          })
        }
      }
    },
    // TODO:获取轮播图下标
     onChange(index) {
      this.bannerIndex = index;
    },

    // TODO:获取轮播图
    getLunBo() {
      let that = this;
      this.$commonPost({
        jydm: "flow_70046",
        flow_70046: {
          home_nbr: "1",
          state: "0",
        },
      })
        .then((resp) => {
          if (resp.resp_code == 0 && resp.resp_result == 0) {
            let arr = [];
            if (resp.image) {
              if (resp.image.length > 0) {
                arr = resp.image;
              } else {
                arr.push(resp.image);
              }
            }
            this.lunboList = arr;
          } else {
            message.error(resp.resp_msg)
          }
        })
        .catch((err) => {
         message.error(err.message)
        });
    },

    // TODO:编辑按钮
    reqEditButton(){
      if(this.userInfo && this.userInfo.nbr == ""){
        return this.$message.warning('您还未登录，登录后即可编辑');
      }else{
        this.visible = true;
      }
    },

    // 获取日期
    onChangeDate(date, dateString) {
      this.dateString = dateString;
      if(this.dateString==""){
        this.dateString = this.getLastDay();
      }
    },

    getLastDay(){
      var y = new Date().getFullYear(); //获取年份
      var m = new Date().getMonth() + 1; //获取月份
      var d = '01'
      m = m < 10 ? '0' + m : m; //月份补 0
      return [y,m,d].join("-")
    },

    onChangeSet(e){
      this.setValue = e.target.value;
    },

    // TODO:轮播图跳转
    reqBannerGo(type, extra){
      if(this.userInfo && this.userInfo.nbr == ""){
        return this.$message.warning('您还未登录，登录后即可查看');
      }else{
        // 跳转轮播商品详情页面
        if (type == 0) {
          return false;
        } else if (type == "1") {
          window.location.href = extra.indexOf('https') == -1 ? "https://" + extra : extra;
        } else if (type == "2") {
          this.$router.push({ path: "/commodityDetail", query: { commNbr: extra } });
        } else if (type == "3") {
          this.$router.push({ path: "/prefectureList", query: { areaNbr: extra } });
        }
      }
    },

     // TODO:个人信息接口
    reqGetData() {
      let that = this;
      this.$commonPost({
        jydm: "flow_10003",
        flow_10003: {
          user_nbr: that.userInfo.nbr,
        },
      })
        .then((resp) => {
          if (resp) {
            that.info = resp;
            that.dateString = resp.birth_day;
            if(that.dateString==''){
              that.dateString = that.getLastDay();
            }
          }
        })
        .catch((err) => {
         message.error(err.message)
        });
    },

    getHotArea(){
      let that = this;
      that.$commonPost({
        jydm: "flow_70060",
        flow_70060: {
          home_nbr: '1',
          type:'1',
          page_index:0,
          num_per_page:100,
        },
      }).then(resp => {
        if(resp.resp_code==0 && resp.resp_result==0){
          let arr = [];
          if(resp.area){
            if(resp.area.length>0){
              arr = resp.area;
            }else{
              arr.push(resp.area);
            }
          }
          that.areaList = arr;
        } else {
          message.error(resp.resp_msg);
        }
      })
      .catch((err) => {
        message.error(err.message);
      });
    },

    // 分页
    onPaging(page, pageSize){
      this.pageIndex = page-1;
      this.numPerPage = pageSize;
      this.getHomeComm();
    },

    // 获取首页商品
    getHomeComm(){
      let that = this;
      that.$commonPost({
        jydm: "flow_70061",
        flow_70061: {
          type:'1',
          page_index:that.numPerPage * that.pageIndex,
          num_per_page:that.numPerPage,
        },
      }).then(resp => {
        if(resp.resp_code==0 && resp.resp_result==0){
          let arr = [];
          if(resp.total_rows){
            that.totalNum = Number(resp.total_rows);
          }
          that.allInCommList = [];
          if(resp.commodityList){
            if(resp.commodityList.commodity.length>0){
              arr = resp.commodityList.commodity;
            }else{
              arr.push(resp.commodityList.commodity);
            }
          }
          that.allInCommList = arr;
        } else {
          message.error(resp.resp_msg);
        }
      })
      .catch((err) => {
        message.error(err.message);
      });
    },
  },
  mounted() {
    // 轮播图
    // this.getLunBo();
    // this.getHomeComm();
  },
  created() {    
    // // 个人信息接口
    // if(this.userInfo && this.userInfo.nbr != ""){
    //   this.reqGetData();
    // }
    // // 查询分类首页专区列表
    // this. getHotArea();
  },
  activated(){
    if(this.$route.meta){
      // 轮播图
      this. getLunBo();
      // 查询分类首页专区列表
      this. getHotArea();
      this.getHomeComm();
    }
     // 个人信息接口
    if(this.userInfo && this.userInfo.nbr != ""){
      this.reqGetData();
    }
  },
};
</script>
<style lang="less" scoped>
.home {
  width: 100%;
  margin: 0 auto;
  background: #faf5f1;
  padding-bottom: 20px;
}
.pagination{
  background: #fff;
  width: 1190px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  // position: fixed;
  // bottom: 0;
}
.modifyBox{
  width: 252px;
  margin-left: 100px;
  .modify{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
}

.homeBox {
  width: 1190px;
  margin: 0 auto;
}
.searchBox {
  margin: 0 auto;
  height: 124px;
  padding-top: 40px;
  .searchFrameBox {
    margin: 0 auto;
    width: 796px;
    height: 42px;
    display: flex;
    .searchFrame {
      cursor: pointer;
      width: 696px;
      height: 42px;
      font-size: 14px;
      color: #999999;
      background: #ffffff;
      border: 1px solid #ff0000;
      padding-left: 10px;
      line-height: 42px;
    }
    .searchFrameBtn {
      cursor: pointer;
      width: 120px;
      height: 42px;
      background: #f32f2f;
      font-size: 18px;
      color: #ffffff;
      text-align: center;
      line-height: 42px;
    }
  }
}

// 个人信息
.personalBox {
  height: 384px;
  display: flex;
  justify-content: space-between;
  .bannerBox {
    width: 796px;
    height: 384px;
    background: #ffffff;
    border-radius: 6px;
    overflow: hidden;
  }
  .informationBox {
    width: 384px;
    height: 384px;
    background: #ffffff;
    border-radius: 6px;
    .infoHeadBox{
      display: flex;
      flex-direction: column;
      align-items: center;
      border-bottom: 1px #F3F3F3 solid;
      justify-content: center;
      height: 106px;
      .infoHead{
        width: 48px;
        height: 48px;
        margin-bottom: 8px;
      }
      .infoName{
        font-size: 14px;
        color: #333333;
        font-weight:bold;
      }
    }
  }

  .commissionAccountBox{
    border-bottom: 1px #F3F3F3 solid;
    height: 76px;
    display: flex;
    .commissionBox{
        width: 128px;
        height: 76px;
        text-align: center;
        padding: 16px 0 16px 0;
        .commissionNum{
          font-size: 12px;
          color: #5684FF;
          margin-bottom: 8px;
          font-weight:bold;
        }
        .commissionText{
          font-size: 12px;
          color: #333333;
          font-weight:bold;
        }
     }
  }
  .functionEntranceBox{
    border-bottom: 1px #F3F3F3 solid;
    height: 76px;
    display: flex;
    .functionBox{
      cursor: pointer;
        width: 96px;
        height: 76px;
        text-align: center;
        padding: 10px 0 10px 0;
        .functionImg{
          width: 30px;
          height: 30px;
          margin-bottom: 8px;
        }
        .functionText{
          font-size: 12px;
          color: #333333;
          font-weight:bold;
        }
     }
  }

  //基础信息
  .basicIntelBox{
    border-bottom: 1px #F3F3F3 solid;
    height: 90px;
    display: flex;
    .basicBox{
      font-weight:bold;
      // flex:1;
      margin-left:20px;
      .basicText{
        font-size: 12px;
        color: #333333;
        margin-top:10px;
      }
    }
  }
  .editButton{
    cursor: pointer;
    width: 100%;
    height: 34px;
    font-size: 12px;
    color: #F32F2F;
    text-align: center;
    line-height: 34px;
  }
}
.swiper-slide{
  cursor: pointer;
  width: 796px;
  height: 384px;
    img{
      width: 796px;
      height: 384px;
    }
}

// 专场
.specialSessionBox{
  margin-top:10px;
}
.specialSession {
  background: #fff;
  border-radius: 6px;
  padding-bottom: 10px;
  margin-top: 10px;
  .specialTitleBox {
    display: flex;
    justify-content: space-between;
    height: 54px;
    padding: 10px 0 10px 10px;
    border-bottom: 1px #f3f3f3 solid;
    margin-bottom: 10px;
    // .specialTitle1 {
    //   padding: 6px 10px 6px 10px;
    //   background: #ff2727;
    //   border-radius: 17px;
    //   text-align: center;
    //   font-size: 16px;
    //   color: #ffffff;
    // }
    .specialTitle1 {
      padding: 0px 10px;
      background: #ff2727;
      border-radius: 17px;
      text-align: center;
      font-size: 16px;
      line-height: 35px;
      color: #ffffff;
    }
    .specialTitle2 {
      cursor: pointer;
      line-height: 35px;
      padding-left: 10px;
      padding-right: 10px;
      border-radius: 17px;
      text-align: center;
      font-size: 16px;
      color: #999;
      margin-right:10px;
    }
  }
  .specialSessionBannerBox {
    cursor: pointer;
    width: 100%;
    height: 260px;
    border-radius: 6px;
    margin: 10px 0 10px 0;
    img {
      margin: 0 10px 0 10px;
      width: 1170px;
      height: 260px;
    }
  }
  .specialSessionListBox {
    width: 100%;
    margin: 10px 0 10px 0;
    .specialListBox {
      display: flex;
      flex-wrap: wrap;
      margin: 0 0px 0 10px;
      .ListBox {
        cursor: pointer;
        margin-right: 18px;
        width: 218px;
        // height: 285px;
        margin-bottom: 20px;
        .ListBoxImg {
          width: 218px;
          height: 218px;
          border-radius: 6px;
        }
        :nth-child(2) {
          font-size: 16px;
          color: #333333;
          margin-top: 10px;
          text-overflow:-o-ellipsis-lastline;
          overflow:hidden; 
          text-overflow: ellipsis; 
          display: -webkit-box; 
          -webkit-line-clamp: 2; 
          -webkit-box-orient: vertical;
        }
        :nth-child(3) {
          font-size: 18px;
          color: #ff0000;
          margin-top: 10px;
        }
      }
    }
  }
}
</style>
<style scoped>
.ant-back-top {
  bottom: 100px;
}
.ant-back-top-inner {
  height: 40px;
  width: 40px;
  line-height: 40px;
  border-radius: 4px;
  background-color: #1088e9;
  color: #fff;
  text-align: center;
  font-size: 20px;
}
.ant-carousel >>> .slick-slide {
  text-align: center;
  height: 384px;
  line-height: 384px;
  background: #364d79;
  overflow: hidden;
}
.ant-input{
  width: 166px;
}

.ant-carousel >>> .slick-slide h3 {
  color: #fff;
}
.ant-carousel >>> .custom-slick-arrow {
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
}
.ant-carousel >>> .custom-slick-arrow:before {
  display: none;
}
.ant-carousel >>> .custom-slick-arrow:hover {
  opacity: 0.5;
}
</style>
