<template>
  <div class="login">
    <!-- 头部 -->
    <div class="loginTop">
      <div class="loginMess">
        <div @click="goHome">去首页</div>
      </div>
    </div>

    <!--  收集登录数据 -->
    <div class="getLoginTop" v-if="modify">
      <div class="getloginMess">
        <div class="logoBox">
          <img class="logoImg" src="../login/img/logo.png" alt="" srcset="" />
          <h1 class="logoText">三本吉市供应链</h1>
        </div>
        <div class="getMessBox">
          <div>
            <span class="spanText">系统登录</span>
            <a-input
              :maxLength="11"
              v-model="phone"
              placeholder="请输入登录账号"
            />
            <a-input-password v-model="password" placeholder="请输入登录密码" />
            <div class="loginBtn" @click="login">登录</div>
            <div class="resetPass" @click="setModify">
              <span>重置密码?</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 更改密码 -->
    <div class="getLoginTop" v-else>
      <div class="getloginMess">
        <div class="logoBox">
          <img class="logoImg" src="../login/img/logo.png" alt="" srcset="" />
          <h1 class="logoText">三本吉市供应链</h1>
        </div>
        <div class="getMessBox">
          <div>
            <span class="spanText">重置密码</span>
            <a-input
              :maxLength="11"
              v-model="phone"
              placeholder="请输入登录账号"
            />
            <div class="inputStyle">
              <input class="inputText" v-model="code" placeholder="请输入短信验证码" type="text">
              <div class="codeStyle">
                <span v-if="codeType" @click="reqPhoneCode">获取验证码</span>
                <span style="color: #999;" v-else>{{captcha}}m后重新获取</span>
              </div>
            </div>
            <a-input-password v-model="password" placeholder="请输入新密码" />
            <div class="loginBtn" @click="setResetPass">确认修改</div>
            <div class="resetPass" @click="setModify">
              <span>去登录?</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { checkempty, checkPsw, checkphone } from "../../assets/js/tool.js";
import { message } from "ant-design-vue";
import { mapMutations, mapGetters } from "vuex";
export default {
  name: "Login",
  components: {},
  data() {
    return {
      captcha:60,
      codeType:true,
      modify:true,
      see: false,
      password: "",
      phone: "",
      code:'',
      loginState: false,
    };
  },
  watch: {
    phone(newValue, oldValue) {
      newValue = newValue.trim();
      newValue = newValue.replace(/[^\d]/g, "");
      if (newValue.length == 11 && !checkphone(newValue))
        return message.warning("手机号格式错误");
      this.phone = newValue;
    },
    password(newValue, oldValue) {
      if (newValue.length > 6 && !checkPsw(newValue))
        return message.warning("密码格式错误");
      this.password = newValue;
    },
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  methods: {
    ...mapMutations({
      sendToken:"SENDToken",
      senduserInfo:"SENDuserInfo",
    }),

    setModify(){
      this.modify = !this.modify;
    },
    
    // 获取手机验证码
    reqPhoneCode(){
      let that = this;
      if (!checkempty(this.phone, "请输入手机号！")) return;
      if (!checkphone(this.phone)) return message.warning("手机号格式错误");
      this.$post("/api/account/applyResetPassword",{
        phoneNo: this.phone,
      }).then(resp=>{
         if (resp.code == "200") {
          let res = resp.object.resp;
          if (res.resp_code == "0" && res.resp_result == "0") {
            that.$message.success(res.resp_msg);
            that.codeType = false;
            let timer = setInterval(() => {
              if(that.captcha <= 0){
                clearInterval(timer);
                that.codeType = true;
                that.captcha = 60;
              }
              that.captcha --;
            }, 1000);
          }else{that.$message.success(res.resp_msg)}
         }else{that.$message.error(resp.message)}
      }).catch(err=>{message.error(that.$message.error(err.message))})
    },

    // 重置密码
    setResetPass(){
      let that = this;
      if (!checkempty(this.phone, "请输入手机号！")) return;
      if (!checkphone(this.phone)) return message.warning("手机号格式错误");
      if (!checkempty(this.code, "请输入短信验证码！")) return;
      if (!checkempty(this.password, "请输入密码！")) return;
      if (!checkPsw(this.password)) {
        return message.warning("密码格式错误");
      }
      this.$post("/api/account/resetPhoneNoPassword",{
        phoneNo: this.phone,
        password: this.md5(this.password),
        code: this.code,
      }).then(resp=>{
        if (resp.code == "200") {
          let res = resp.object.resp;
          if (res.resp_code == "0" && res.resp_result == "0") {
            that.$message.success(res.resp_msg);
            that.phone = '';
            that.password = '';
            that.code = '';
          }else{that.$message.success(res.resp_msg)};
         }else{that.$message.error(resp.message)};
      }).catch(err=>{message.error(that.$message.error(err.message))});
    },

    // 登录
    login() {
      let that = this;
      if (!checkempty(this.phone, "请输入手机号！")) return;
      if (!checkphone(this.phone)) return message.warning("手机号格式错误");
      if (!checkempty(this.password, "请输入密码！")) return;
      if (!checkPsw(this.password)) {
        return message.warning("密码格式错误");
      }
      if (this.loginState) return;
      this.loginState = true;
      this.$post("/api/customer/phoneNoLogin", {
        phoneNo: this.phone,
        password: this.md5(this.password),
      })
        .then((res) => {
          if (res.code == "200") {
            let resp = res.object;
            if (resp.resp.resp_code == "0" && resp.resp.resp_result == "0") {
              let userInfo = {
                nbr: resp.resp.user_nbr,
              };
              that.senduserInfo(userInfo);
              that.sendToken(resp.resp.token);
              localStorage.setItem("userInfo", JSON.stringify(userInfo));
              localStorage.setItem("token", resp.resp.token);
              message.success("登录成功");
              // if (window.history.length > 1) {
              //   that.$router.go(-1);
              // } else {
                that.$router.push("/");
              // }
            } else {
              that.loginState = false;
              message.success(resp.resp.resp_msg);
            }
          } else {
            that.loginState = false;
            message.error(resp.message);
          }
        })
        .catch((err) => {
          that.loginState = false;
          message.error("您现在的网络不稳定，请稍后再试");
        });
    },
    goHome() {
      this.$router.push({
        path: "/Home",
      });
    },
  },
};
</script>
<style lang="less" scoped>
.login {
  width: 100%;
  margin: 0 auto;
  background: #ffffff;
  .loginTop {
    width: 100%;
    height: 64px;
    background: #ff8e47;
    .loginMess {
      cursor: pointer;
      width: 1190px;
      height: 64px;
      margin: 0 auto;
      line-height: 64px;
      :nth-child(1) {
        font-size: 14px;
        color: #ffffff;
        float: right;
      }
    }
  }

  .inputStyle:hover { border:solid 1px #40a9ff;}
  .inputStyle:focus { border:solid 1px #d9d9d9;}

  .inputStyle{
    display: flex;
    width: 100%;
    height: 40px;
    border-radius: 4px;
    transition: all 0.3s;border-color: #40a9ff;
    border: 1px solid #d9d9d9;
    margin-top: 10px;
    align-items: center;
    input{
	    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #a5a5a5;
      }
      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #a5a5a5;
      }
      &::-ms-input-placeholder { /* Microsoft Edge */
        color: #a5a5a5;
      }
    }
    .inputText{
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      font-variant: tabular-nums;
      list-style: none;
      font-feature-settings: 'tnum';
      position: relative;
      display: inline-block;
      width: 180px;
      height: 36px;
      padding: 4px 11px;
      color: rgba(0, 0, 0, 0.65);
      font-size: 14px;
      background-color: #fff;
      border: 0px;
      border-right-width: 1px !important;
    }
    .codeStyle{
      text-align: center;
      width: 120px;
      :nth-child(1){
        cursor: pointer;
        color: #1890ff;
      }
      :nth-child(2){
        cursor: pointer;
        color: #999;
      }
    }
  }
  ::selection {
    color: #fff;
    background: #1890ff;
  }

  .getLoginTop {
    width: 100%;
    margin-top: 100px;
    padding-top: 40px;
    padding-bottom: 40px;
    background: #ff8e47;
    .getloginMess {
      width: 480px;
      padding-bottom: 20px;
      background: #ffffff;
      margin: 0 auto;
      .logoBox {
        width: 480px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .logoImg {
          margin-top: 30px;
          width: 184px;
          height: 98px;
        }
        .logoText {
          margin-top: 10px;
          margin-bottom: 20px;
          font-size: 34px;
          color: #3a3a3a;
        }
      }

      .getMessBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 480px;
        .spanText {
          font-size: 18px;
          color: #333333;
          margin-bottom: 10px;
          display: block;
          font-weight: bold;
        }
        .loginBtn {
          cursor: pointer;
          height: 40px;
          padding: 0 15px;
          font-size: 16px;
          border-radius: 4px;
          background: #ff8e47;
          color: #ffffff;
          text-align: center;
          line-height: 40px;
          margin-top: 20px;
        }
        .resetPass{
          margin-top: 10px;
          color: #333;
          span{
            cursor: pointer;
            font-size:14px;
          }
        }
      }
    }
  }
}
</style>
<style scoped>
.login >>> .ant-input-password {
  margin-top: 10px;
}
.login >>> .ant-input {
  height: 40px;
}
</style>
