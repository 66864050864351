


import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);


//import * as actions from './actions.js'
import * as getters from './getters.js'
import mutations from './mutations.js'
import state from './state.js'


export default new Vuex.Store({           //实例化一个store仓库 
	//actions,
	mutations,
	state,
	getters 
})
