<template>
  <div class="commStatistics">
    <div style="height: 64px; width: 100%"></div>
    <div class="resultOrderBox">
       <div class="managementTopBox">
        <div class="managementTitle">佣金资金统计</div>
        <div class="managementCapital">
          <div class="capitalBox">
            <div>{{sumAmountNum || "0.00"}}</div>
            <div>总业绩</div>
          </div>
          <div class="capitalBox">
            <div>{{totalNum || "0"}}</div>
            <div>订单数</div>
          </div>
          <div class="capitalBox">
            <div>{{amountNum || "0.00"}}</div>
            <div>收益金额(元)</div>
          </div>
        </div>
      </div>

      <!-- 时间选择 -->
      <div class="queryBox">
        <div class="inputQueryBox">
          <div class="timeQuery">下单时间：<a-range-picker :default-value="[moment(timeData[0]), moment(timeData[1])]"
          :value="[moment(timeData[0]), moment(timeData[1])]"
          :inputReadOnly="true" :locale="locale"  @change="onChange" /></div>
          <div class="conditionQuery">条件查询：<a-input style="width:272px" v-model="queryMess" placeholder="请输入商品名称" /></div>
        </div>

        <!-- 确认按钮 -->
        <div class="orderStatus">
          <a-button type="primary" class="bePaid" @click="reqBrokerageList">搜索</a-button> <a-button @click="reqReset">重置</a-button>
        </div>
      </div>

      <!-- 表格 -->
      <div class="formBox">
        <table>
          <tr>
            <th style="width: 514px;">商品信息</th>
            <th style="width: 252px;">订单日期</th>
            <th style="width: 252px;">付款日期</th>
            <th style="width: 198px;">商品金额</th>
            <th style="width: 134px;">收益</th>
            <th style="width: 134px;">订单状态</th>
            <th style="width: 214px;">操作</th>
          </tr>
          <tr class="myStyle" :class="index % 2 == 0 ? 'myStyle1 myStyle2' : 'myStyle myStyle2'" v-for="(item,index) in list" :key="item.key">
            <td>
              <div class="commMess">
                <img class="commMessImg" :src="item.commodity_logo" alt="">
                <div class="commText">
                  <div style="font-size: 12px;color: #000000;margin-bottom: 10px;">{{item.commodity_name}}</div>
                  <div style="font-size: 12px;color: #999;margin-bottom:5px;">昵称：{{item.nick_name}}</div>
                  <div style="font-size: 12px;color: #999;margin-bottom:5px;">编号：{{item.show_nbr}}</div>
                  <div style="font-size: 12px;color: #333;margin-bottom:5px;">状态：{{item.is_settle==0?"待结算":item.is_settle==1?"已结算":"已取消" }}</div>
                </div>
              </div>
            </td>
            <td>{{item.create_time}}</td>
            <td>{{item.update_time || "暂无"}}</td>
            <td>{{item.commodity_amount}}</td>
            <td>{{item.amount}}</td>
            <td>{{item.state==1?'添加到购物车':item.state==4?'未支付':item.state==6?'付款成功'
              :item.state==7?'已发货':item.state==8?'已收货':item.state==10?'关闭':''}}
            </td>
            <td style="width: 240px;">
              <div class="btn">
                <a @click="showModal(item.commodity_logo,item.commodity_name,item.commodity_amount,
                  item.property_simple,item.show_nbr,item.sum_amount,item.coupon_amount,item.postage)">查看商品详情
                </a>
              </div>
            </td>
          </tr>
        </table>
      </div>

    <!-- 弹窗 -->
    <a-modal
      title="商品明细详情"
      :visible="visible"
      @ok="handleOk"
      @cancel="handleCancel"
      cancelText="取消"
      okText="确认"
    >
      <div class="commMess1">
        <img class="commMessImg1" :src="commLogo" alt="">
        <div class="commText3">
          <div style="font-size: 12px;color: #000000;margin-bottom: 10px;">{{commName}}</div>
          <div style="font-size: 12px;color: #000000;margin-bottom: 10px;">规格：{{commSimple}}</div>
          <div style="font-size: 12px;color: red;margin-bottom: 10px;">￥{{commAmount}}</div>
        </div>
      </div>
      <div class="commText2">
        <div>订单号：{{commNbr}}</div>
        <div>商品小计：￥{{commAmount}}</div>
        <div>实际支付：￥{{commSumAmount}}</div>
        <div>优惠券金额：￥{{couponAmount}}</div>
        <div>运费：￥{{updateTime}}</div>
      </div> 
    </a-modal>

      <div style="height: 64px; width: 100%"></div>
        <!-- 分页 -->
        <div class="pagination">
          <a-pagination href="javascript:;" @change="onPaging" :total="totalNum"/>
        </div> 
    </div>
  </div>
</template>
 
<script>
// 默认语言为 en-US，如果你需要设置其他语言，推荐在入口文件全局设置 locale
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import { mapGetters } from "vuex";
import moment from 'moment';
import 'moment/locale/zh-cn';
export default {
  name: "CommStatistics",
  components: {},
  data() {
    return {
      locale,
      list: [],
      queryMess:"",
      timeData:["2001-1-1","2045-1-1"],
      current:1,
      visible:false,
      pageIndex:0,
      numPerPage:10,
      totalNum:0,
      commLogo:'',
      commName:'',
      commSimple:'',
      commNbr:'',
      commAmount:'',
      commSumAmount:'',
      updateTime:"",
      couponAmount:'',

      amountNum:0,
      sumAmountNum:0,
    };
  },
  computed:{
    ...mapGetters(['userInfo'])
  },
  methods: {
    moment,
    
    // 重置
    reqReset(){
      this.timeData[0] = this.getLastDay();
      this.timeData[1] = this.getLastAfterDay();
      this.queryMess = '';
      this.reqBrokerageList();
    },

    showModal(logo,name,amount,simple,showNbr,sumAmount,couponAmount,updateTime) {
      this.visible = true;
      this.commLogo = '';
      this.commSimple = '';
      this.commNbr = '';
      this.commAmount = '';
      this.commName = '';
      this.commSumAmount = '';
      this.couponAmount='',
      this.updateTime="",

      this.commLogo = logo;
      this.commSimple = simple;
      this.commNbr = showNbr;
      this.commAmount = amount;
      this.commName = name;
      this.commSumAmount = sumAmount;
      this.couponAmount=couponAmount;
      this.updateTime=updateTime;
    },

    handleOk(e) {
      this.visible = false;
    },
    handleCancel(e) {
      this.visible = false;
    },

    onChange(date, dateString) {
      this.timeData = dateString;
    },

    onPaging(page, pageSize){
      this.pageIndex = page-1;
      this.numPerPage = pageSize;
      this.reqBrokerageList();
    },

    reqBrokerageList(){
      let that = this;
      this.$commonPost({
        jydm: "flow_30010",
        flow_30010: {
          start_time:that.timeData[0],
          end_time:that.timeData[1],
          user_nbr:that.userInfo.nbr,
          search:that.queryMess,
          page_index:that.numPerPage * that.pageIndex,
          num_per_page:that.numPerPage,
        },
      }).then(resp=>{
        if(resp.resp_code==0 && resp.resp_result==0){
          let arr = [];
          if(resp.total_rows){
          that.totalNum = Number(resp.total_rows);
          }
          that.amountNum = Number(resp.amount);
          that.sumAmountNum = Number(resp.sum_amount);
          if(resp.list){
            if(resp.list.length>0){
              arr = resp.list;
            }else{
              arr.push(resp.list)
            }
          }
          that.list = [];
          that.list = arr;
        }else{
          that.$message.error(resp.resp_msg)
        }
      }).catch(err=>{that.$message.error(err.message)})
    },

    getLastDay(){
      var y = new Date().getFullYear(); //获取年份
      var m = new Date().getMonth() + 1; //获取月份
      var d = '01'
      m = m < 10 ? '0' + m : m; //月份补 0
      return [y,m,d].join("-")
    },
    getLastAfterDay(){
      var y = new Date().getFullYear(); //获取年份
      var m = new Date().getMonth() + 1; //获取月份
      var d = new Date(y, m, 0).getDate(); //获取当月最后一日
      m = m < 10 ? '0' + m : m; //月份补 0
      d = d < 10 ? '0' + d : d; //日数补 0
      return [y,m,d].join("-")
    },
  },
  mounted() {},
  created() {
    this.timeData[0] = this.getLastDay();
    this.timeData[1] = this.getLastAfterDay();
    this.reqBrokerageList();
  },
};
</script>
<style lang="less" scoped>

.commStatistics {
  width: 100%;
  margin: 0 auto;
  background: #f0f0f0;
  padding-bottom: 20px;
}
.resultOrderBox {
  width: 1190px;
  margin: 0 auto;
}
.managementTopBox {
  margin-bottom: 10px;
  height: 194px;
  background: #ffffff;
  .managementTitle {
    height: 58px;
    line-height: 58px;
    margin-left: 20px;
    font-size: 16px;
    color: #444444;
  }
  .managementCapital {
    display: flex;
    height: 136px;
    .capitalBox {
      font-size: 16px;
      color: #595961;
      width: 396px;
      height: 136px;
      padding: 38px 0 38px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      :nth-child(1) {
        margin-bottom: 8px;
      }
    }
  }
}
.queryBox {
  background: #fff;
  padding-left: 32px;
  .inputQueryBox{
    height: 90px;
    display: flex;
    align-items: center;
    border-bottom: 1px #f0f0f0 solid;
    .conditionQuery{
      width: 470px;
      margin-left: 40px;
    }
  }
  .orderStatus{
      height: 60px;
      display: flex;
      align-items: center;
      .bePaid{
        margin-right: 20px;
      }
  }
}
.formBox{
  background: #fff;
  margin-top: 10px;
  font-size: 12px;
  .viewOrder{
    width: 72px;
    height: 15px;
    padding-right: 10px;
    border-right: 1px #D8D8D8 solid;
  }
  table{
    width: 100%;
    text-align: center;
    border: 0px;
    th{
      border-bottom:1px solid #DCDEE3;
      background: #EBECF0;
      padding: 10px;
    }
    td{
      //  height: 120px;
      padding: 10px 0;
    }
  }

  .myStyle2:hover{
    background: #F2F2F2;
    padding: 10px 0;
  }
  .myStyle{
    background: #f8f8f8;
    padding: 10px 0;
  }
  .myStyle1{
    background: #fff;
    padding: 10px 0;
  }
}

.commMess{
  width: 100%;
  display: flex;
  justify-content: center;
  .commMessImg{
    width:70px;
    height:70px;
  }
  .commText{
    width: 240px;
    text-align: left;
    margin-left: 10px;
  }
}

.commMess1{
  width: 100%;
  display: flex;
  border-bottom: 1px #e4e4e4 solid;
  padding-bottom: 10px;
  .commMessImg1{
    width:70px;
    height:70px;
  }
}
.btn a{
  font-weight: bold;
}
.pagination{
  background: #fff;
  width: 1190px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
}

.commText3{
  width: 240px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
   margin-left: 10px;
  :nth-child(1){
    font-size: 12px;
    color: #000000;
    margin-bottom: 10px;
  }
  :nth-child(2){
    font-size: 12px;
    color: #999;
  }
  :nth-child(3){
    font-size: 12px;
    color: red;
  }
}

.commText2{
  width: 240px;
  text-align: left;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  div{
    font-size: 12px;
    color: #000000;
    margin-bottom: 10px;
  }
}
</style>
<style scoped>
#app{
   background: #f0f0f0;
}
</style>
