<template>
  <div class="paymentStatus">
    <div style="height: 64px; width: 100%"></div>
    <div class="paymentStatusBox">
      <div class="queryBox" v-if="type==3">支付成功(等待审核)</div>
      <div class="queryBox" v-else>支付成功</div>
      <!-- 表格 -->
      <div class="formBox">
        <table>
          <tr>
            <th style="width: 140px;">总订单创建时间</th>
            <th style="width: 140px;">订单商品总数</th>
            <th style="width: 138px;">订单商品总价</th>
            <th style="width: 114px;">订单商品付款时间</th>
            <th style="width: 112px;">订单支付状态</th>
            <th style="width: 132px;">订单商品运费</th>
            <th style="width: 142px;">操作</th>
          </tr>
          <tr class="myStyle" :class="'myStyle1 myStyle2'">
            <td>{{dataList.create_time || "暂无"}}</td>
            <td>{{dataList.total_rows || "0"}}</td>
            <td>{{dataList.amount || "0"}}</td>
            <td>{{dataList.update_time || "暂无"}}</td>
            <td>{{dataList.final_state == "4" ? "待支付" : dataList.final_state == "6" ? "已支付" : '暂无'}}</td>
            <td>{{dataList.postage || "0"}}</td>
            <td style="display: flex; align-items: center;justify-content: center;color:#007AFF;">
              <div class="btn">
                <div style="width: 100px;" @click="goPage(dataList.order_nbr)">查看订单商品</div>
              </div>
            </td>
          </tr>
        </table>
      </div>
      <div class="statusMess">
        <div class="statusQueryBox">支付信息</div>
        <p>订单总金额：<i>￥{{dataList.amount || "0"}}</i></p>
        <p>实付总金额：<i>￥{{dataList.sum_amount || "0"}}</i></p>
        <p>订单支付方式：
          {{dataList.money_pay_type == "ALIPAYPAGE" ? "支付宝": dataList.money_pay_type == "WECHATPAY" ? "微信":
          dataList.money_pay_type == "OFFLINEBANK" ? "银行卡":"暂无"}}
        </p>
        <div class="payImg" v-if="dataList.money_pay_type == 'OFFLINEBANK'"><span>银行卡支付凭证：</span><img :src="imgSee" alt="" srcset=""></div>
      </div>
      <div style="height: 64px; width: 100%"></div>
    </div>
  </div>
</template>
 
<script>
export default {
  name: "PaymentStatus",
  components: {},
  data() {
    return {
      dataList:{},
      imgSee:'',
      orderNbr:'',
      type:'',
    };
  },
  methods: {
    goPage(nbr){
      this.$router.push({
        path:'/orderCommodity',
        query:{orderNbr : nbr}
      })
    },

    reqSubDetailsList(){
      let that = this;
      this.$commonPost({
        jydm: "flow_30025",
        flow_30025: {
          order_nbr:that.orderNbr,
          search:that.queryMess,
          page_index:0,
          num_per_page:10,
        },
      }).then(resp=>{
       if(resp.resp_code==0 && resp.resp_result==0){
        that.dataList = resp;
        that.imgSee = that.dataList.certificate;
       }else{
        that.$message.error(resp.resp_msg)
       }
      })
      .catch(err=>{
        that.$message.error(err.message)
      })
    },

  },
  mounted() {},
  created() {
    let nbr = this.$route.query.orderNbr;
    this.type = this.$route.query.type;
    window.localStorage.setItem('orderNbr',nbr);
    this.orderNbr = window.localStorage.getItem('orderNbr');
    this.reqSubDetailsList();
  },
};
</script>
<style lang="less" scoped>
.paymentStatus {
  width: 100%;
  margin: 0 auto;
  background: #f0f0f0;
  padding-bottom: 20px;
}
.paymentStatusBox {
  width: 1190px;
  margin: 0 auto;
}
.queryBox {
  font-size: 20px;
  color: #424242;
  background: #fff;
  padding-left: 22px;
  line-height: 70px;
  font-weight: bold;
}
.formBox{
  background: #fff;
  margin-top: 10px;
  font-size: 12px;
  .viewOrder{
    width: 72px;
    height: 15px;
    padding-right: 10px;
    border-right: 1px #D8D8D8 solid;
  }
  table{
    width: 100%;
    text-align: center;
    border: 0px;
    th{
      border-bottom:1px solid #DCDEE3;
      background: #EBECF0;
      padding: 10px;
    }
    td{
       height: 120px;
    }
  }

  .myStyle2:hover{
    background: #F2F2F2;
  }
  .myStyle{
    background: #f8f8f8;
  }
  .myStyle1{
    background: #fff;
  }
}
.btn{
  cursor: pointer;
  display: flex;
}
.btn div{
  font-weight: bold;
  color:#007AFF;
}
.statusMess{
  padding: 0 22px 10px 22px;
  margin-top: 10px;
  background: #fff;
  .statusQueryBox{
    margin-bottom: 10px;
    font-size: 14px;
    color: #3A3A3A;
    background: #fff;
    line-height: 50px;
    font-weight: bold;
    border-bottom: 1px  #E8E8E8 solid;
  }
  p{
    margin-bottom: 10px;
  }
  .payImg{
    display: flex;
  }
  p,div{
    font-size: 14px;
    color: #424242;
    i{
      font-size: 14px;
      color: #FF0000;
    }
    img{
      width: 100px;
      height: 100px;
    }
  }
}
</style>
<style scoped>
</style>
