<template>
  <div class="orderDetail">
    <div class="orderDetailBox">
      <div class="orderDetailTop">
        <div class="orderTitle">订单详情</div>
        <div class="orderMess">
          <span>订单编号：{{dataList.show_nbr || "暂无"}}</span>
          <span>小订单ID：{{commRecordNbr || "暂无"}}</span>
          <span>商户小订单ID：{{dataList.ext_record_nbr || "暂无"}}</span>
        </div>
      </div>

      <!-- 列表 -->
      <div class="formBox" v-for="(item,index) in list" :key="index">
        <div class="myStyleTitle">
            <div style="width: 362px;">商品信息</div>
            <div style="width: 178px;">商品编号</div>
            <div style="width: 130px;">购买数量</div>
            <div style="width: 158px;">单价（元）</div>
            <div style="width: 158px;">运费</div>
            <div style="width: 204px;">小计</div>
        </div>

        <div class="orderItem">
          <div>
            <span>创建时间：{{dataList.create_time || "暂无"}}</span>
            <span>付款时间：{{ dataList.paid_time || "暂无"}}</span>
          </div>
        </div>

        <div class="myStyle" >
          <div class="commMess" style="width: 362px;">
            <img class="commMessImg" :src="item.commodity_first_pic" alt="">
            <div class="commText">
              <div>{{item.commodity_name || "暂无"}}</div>
              <div>{{item.property || "暂无"}}</div>
              <div>编号：{{item.commodity_nbr || "暂无"}}</div>
            </div>
          </div>
          <div class="commMess1" style="width: 178px;">{{item.commodity_nbr || "暂无"}}</div>
          <div class="commMess1" style="width: 130px;">{{item.quantity || "暂无"}}</div>
          <div class="commMess1" style="width: 158px;">{{item.sell_price || "暂无"}}</div>
          <div class="commMess1" style="width: 158px;">{{item.postage || "暂无"}}</div>
          <div class="commMess1" style="width: 204px;">{{dataList.sum_amount || "暂无"}}</div>
        </div>

        <div class="orderItem">
          <div class="orderText">
            <span></span>
            <span>实际支付：<i v-if="item.sum_amount > 0">¥</i>{{dataList.sum_amount || "暂无"}}</span>
          </div>
        </div>
      </div>

      <!-- 订单信息 -->
      <div class="orderMessBott">
        <div class="orderMessText">订单信息</div>
        <div class="orderBox">
          <div>付款状态：{{dataList.final_state == 4? '未支付' : dataList.final_state == 6 ? '已支付':
            dataList.final_state == 7? '已发货':dataList.final_state == 8? '已收货':dataList.final_state == 10? '关闭':'暂无'}}
          </div>
          <div>付款方式：{{dataList.money_pay_type == "ALIPAYPAGE" ? "支付宝":
            dataList.money_pay_type == "WECHATPAY" ? "微信":
            dataList.money_pay_type == "OFFLINEBANK" ? "线下银行转账":"暂无"}}
          </div>
          <div>收货信息：{{ dataList.full_address || "暂无"}}</div>
          <!-- <div>会员编号/昵称/手机号/姓名：{{dataList.user_nbr || "暂无"}}/{{dataList.nick_name || "暂无"}}/{{dataList.phone_no || "暂无"}}/{{dataList.contacts || "暂无"}}</div> -->
          <div>收货人姓名：{{dataList.contacts || "暂无"}}</div>
          <div>收货人手机：{{dataList.phone_no || "暂无"}}</div>
        </div>
        <div class="comments">买家留言：<p>{{dataList.message || "暂无"}}</p></div>
      </div>
    </div>
  </div>
</template>
<script>
export default ({
  data() {
    return{
      orderNbr:"",
      list:[],
      dataList:{},
      commRecordNbr:0,
    }
  },
  methods:{
    reqSubDetailsList(){
      let that = this;
      this.$commonPost({
        jydm: "flow_30022",
        flow_30022: {
          order_nbr:that.orderNbr,
        },
      }).then(resp=>{
       if(resp.resp_code==0 && resp.resp_result==0){
        that.list = [];
        let arr = [];
        that.dataList = resp;
        if(resp.commodity){
          if(resp.commodity.length>0) arr = resp.commodity;
          else arr.push(resp.commodity);
        }
        that.list = arr;
        that.commRecordNbr = that.list[0].commodity_record_nbr;
       }else{
        that.$message.error(resp.resp_msg)
       }
      })
      .catch(err=>{
        that.$message.error(err.message)
      })
    },
  },
  activated(){
    this.orderNbr = this.$route.query.orderNbr;
    this.reqSubDetailsList();
    window.scrollTo(0, this.scroll);
    document.body.scrollTop = 0;
  },
  mounted() {
    this.orderNbr = this.$route.query.orderNbr;
    this.reqSubDetailsList();
  },
  created() {
    window.scrollTo(0, this.scroll);
    document.body.scrollTop = 0;
  },
})
</script>
<style lang="less" scoped>
.orderDetail{
  width: 100%;
  margin: 0 auto;
}
.orderDetailBox{
  padding-top: 64px;
  width: 1190px;
  margin: 0 auto;
}
.orderDetailTop{
   width: 100%;
   height: 102px;
   padding-left: 20px;
   background: #fff;
   .orderTitle{
    font-size: 20px;
    color: #333333;
    line-height: 48px; 
    font-weight: bold;
   }
   .orderMess{
      font-size: 14px;
      color: #3A3A3A;
      line-height: 54px; 
      span{
        margin-right: 24px;
      }
   }
}
.formBox{
  background: #fff;
  margin-top: 10px;
  font-size: 12px;
  .viewOrder{
    width: 72px;
    height: 15px;
    padding-right: 10px;
    border-right: 1px #D8D8D8 solid;
  }
  table{
    width: 100%;
    text-align: center;
    border: 0px;
    th{
      border-bottom:1px solid #DCDEE3;
      background: #EBECF0;
      padding: 10px;
    }
    td{
      height: 120px;
    }
  }
  .orderItem{
    padding: 0 10px 0 10px;
    div{
      width: 100%;
      border-bottom: 1px #E8E8E8 solid;
      :nth-child(1){
        margin-right: 20px;
      }
    }
    span{
    line-height: 58px;
    }
    margin-bottom: 10px;
    .orderText{
        border-top: 1px #E8E8E8 solid;
      display: flex;
      justify-content: space-between;
      :nth-child(2){
        font-size: 14px;
        color: #FF0000;
      }
    }
  }
  .myStyle{
    padding: 0 10px 0 10px;
    background: #fff;
    display:flex;
    margin-bottom: 10px;
  }
  .myStyleTitle{
    padding: 0 10px 0 10px;
    display:flex;
    margin-bottom: 10px;
    line-height: 40px;
    text-align: center;
    background: #EBECF0;
    border-bottom: 1px #E8E8E8 solid;
    font-size: 12px;
    color: #444444;
    font-weight: bold;
  }
  .btn div{
    font-weight: bold;
    color:#007AFF;
    cursor: pointer;
  }
  .commMess1{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .commMess{
    display: flex;
    .commMessImg{
      width:70px;
      height:70px;
    }
    .commText{
      width: 240px;
      text-align: left;
      margin-left: 10px;
      :nth-child(1){
        font-size: 12px;
        color: #000000;
        margin-bottom: 10px;
      }
      :nth-child(2){
        font-size: 12px;
        color: #999;
      }
      :nth-child(3){
        font-size: 12px;
        color: #999;
      }
    }
  }
}
.orderMessBott{
  padding: 0 10px 10px 10px;
  margin-top: 10px;
  background: #fff;
  .orderMessText{
    line-height: 52px;
    font-size: 14px;
    color: #333333;
    border-bottom: 1px #E8E8E8 solid;
    margin-bottom: 10px;
  }
}
.orderBox{
  border-bottom: 1px #E8E8E8 solid;
  div{
    margin-bottom: 10px;
  }
}
.comments{
  font-size: 14px;
  color: #444444;
  display: flex;
  margin-top: 10px;
    p{
      width: 708px;
      font-size: 14px;
      color: #444444;
    }
}
</style>
