<template>
  <div class="commCollection">
    <div style="height: 64px; width: 100%"></div>
    <div class="resultOrderBox">
      <!-- 时间选择 -->
      <div class="queryBox">
        <div class="inputQueryBox">
          <!-- <div class="timeQuery">下单时间：<a-range-picker :locale="locale" :default-value="[moment(timeData[0]), moment(timeData[1])]"  @change="onChange" /></div> -->
          <div class="conditionQuery">商品名称查询：<a-input style="width:172px" v-model="queryMess" placeholder="请输入商品名称信息" /></div>
        </div>

        <!-- 确认按钮 -->
        <div class="orderStatus">
          <a-button type="primary" @click="searchList" class="bePaid">搜索</a-button>
          <a-button @click="resetList">重置</a-button>
        </div>
      </div>

      <!-- 表格 -->
      <div class="formBox">
        <table>
          <tr>
            <th style="width: 514px;">商品信息</th>
            <th style="width: 252px;">库存</th>
            <th style="width: 198px;">商品金额</th>
            <th style="width: 226px;">操作</th>
          </tr>
          <tr class="myStyle" :class="index % 2 == 0 ? 'myStyle1 myStyle2' : 'myStyle myStyle2'" v-for="(item,index) in list" :key="item.nbr">
            <td>
              <div class="commMess">
                <img class="commMessImg" :src="item.commodity_logo" alt="">
                <div class="commText">
                  <div>{{item.commodity_name}}</div>
                  <div>编号：{{item.nbr}}</div>
                </div>
              </div>
            </td>
            <td>{{item.quantity}}</td>
            <td>{{item.sell_price}}</td>
            <td style="width: 240px;">
              <div class="btn">
                <!-- border-right: 1px #D8D8D8 solid;margin-right: 10px; padding-right: 10px; -->
                <a style="width: 100px;margin-right: 10px;" @click="goDetail(item.nbr)">查看商品详情</a>
                <a @click="reqCollection(item.nbr)">取消收藏</a>
              </div>
            </td>
          </tr>
        </table>
      </div>

      <div style="height: 64px; width: 100%"></div>
      <!-- 分页 -->
      <div class="pagination">
        <a-pagination href="javascript:;" @change="onPaging" :total="totalNum"/>
      </div>

    </div>
  </div>
</template>
 
<script>
import { mapGetters } from "vuex";
export default {
  name: "CommCollection",
  components: {},
  data() {
    return {
      list: [],
      queryMess:"",
      numPerPage:10,
      pageIndex:0,
      totalNum:0,
      timeData:[],
      collection:0,
    };
  },
  computed:{
    ...mapGetters(["userInfo"])
  },
  methods: {
    reqCollection(nbr){
      if(this.userInfo && this.userInfo.nbr == ""){
        return this.$message.warning('您还未登录，登录后即可收藏');
      }else{
        this.callCollect(nbr);
      }
    },

    // 取消收藏 
    callCollect(nbr){
      let that = this;
      let commNbr = {nbr:[`${nbr}`]};
      this.$commonPost({
        jydm: "flow_10013",
         flow_10013: {
          commodity:commNbr,
          user_nbr:that.userInfo.nbr,
        },
      }).then(resp=>{
          if(resp.resp_code === "0"&& resp.resp_result==="0"){
            that.$message.success(resp.resp_msg);
            that.reqDataList();
          } else {
            that.$message.error(resp.resp_msg);
          }
      }).catch((err) => {
        that.$message.error(err.message);
      });
    },

    searchList(){
      let that = this;
      that.reqDataList();
    },

    resetList(){
      let that = this;
      that.queryMess = "";
      // this.timeData[0] = this.getLastDay();
      // this.timeData[1] = this.getLastAfterDay();
      that.reqDataList();
    },

    goDetail(nbr){
      this.$router.push({
        path:'/commodityDetail',
        query:{commNbr:nbr}
      })
    },

    onPaging(page, pageSize){
      this.pageIndex = page-1;
      this.numPerPage = pageSize;
      this.reqDataList();
    },

    reqDataList(){
      let that = this;
      this.$commonPost({
        jydm: "flow_10014",
         flow_10014: {
          commodity_name:that.queryMess,
          user_nbr:that.userInfo.nbr,
          page_index:that.numPerPage * that.pageIndex,
          num_per_page:that.numPerPage,
        },
      }).then(resp=>{
        if(resp.resp_code == '0' && resp.resp_result == '0'){
          that.list = [];
          let arr = [];
          that.totalNum = Number(resp.total_rows);
          if(resp.commodityList){
            if(resp.commodityList.length > 0){
                arr = resp.commodityList;
            }else{
              arr.push(resp.commodityList)
            }
            that.list = arr;
          }
        }else{
          that.$message.success(resp.resp_msg)
        }
      })
      .catch(err=>{
        that.$message.error(err.message)
      })
    },
    
    onChange(date, dateString) {
      this.timeData = dateString;
    },
    getLastDay(){
      var y = new Date().getFullYear(); //获取年份
      var m = new Date().getMonth() + 1; //获取月份
      var d = '01'
      m = m < 10 ? '0' + m : m; //月份补 0
      return [y,m,d].join("-")
    },
    getLastAfterDay(){
      var y = new Date().getFullYear(); //获取年份
      var m = new Date().getMonth() + 1; //获取月份
      var d = new Date(y, m, 0).getDate(); //获取当月最后一日
      m = m < 10 ? '0' + m : m; //月份补 0
      d = d < 10 ? '0' + d : d; //日数补 0
      return [y,m,d].join("-")
    },
  },
  mounted() {},
  created() {
    // this.timeData[0] = this.getLastDay();
    // this.timeData[1] = this.getLastAfterDay();
    this.reqDataList();
  },
};
</script>
<style lang="less" scoped>

.commCollection {
  width: 100%;
  margin: 0 auto;
  background: #f0f0f0;
  padding-bottom: 20px;
}
.resultOrderBox {
  width: 1190px;
  margin: 0 auto;
}
.queryBox {
  background: #fff;
  padding-left: 32px;
  .inputQueryBox{
    height: 90px;
    display: flex;
    align-items: center;
    border-bottom: 1px #f0f0f0 solid;
    .conditionQuery{
      width: 470px;
      // margin-left: 40px;
    }
  }
  .orderStatus{
      height: 60px;
      display: flex;
      align-items: center;
      .bePaid{
        margin-right: 20px;
      }
  }
}
.formBox{
  background: #fff;
  margin-top: 10px;
  font-size: 12px;
  .viewOrder{
    width: 72px;
    height: 15px;
    padding-right: 10px;
    border-right: 1px #D8D8D8 solid;
  }
  table{
    width: 100%;
    text-align: center;
    border: 0px;
    th{
      border-bottom:1px solid #DCDEE3;
      background: #EBECF0;
      padding: 10px;
    }
    td{
       height: 120px;
    }
  }

  .myStyle2:hover{
    background: #F2F2F2;
  }
  .myStyle{
    background: #f8f8f8;
  }
  .myStyle1{
    background: #fff;
  }
}

.commMess{
  width: 100%;
  display: flex;
  justify-content: center;
  .commMessImg{
    width:70px;
    height:70px;
  }
  .commText{
    width: 240px;
    text-align: left;
    margin-left: 10px;
    :nth-child(1){
      font-size: 12px;
      color: #000000;
      margin-bottom: 10px;
    }
    :nth-child(2){
      font-size: 12px;
      color: #999;
    }
  }
}
.btn a{
  font-weight: bold;
}
.pagination{
  background: #fff;
  width: 1190px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
}
</style>
<style scoped>
#app{
   background: #f0f0f0;
}
</style>
