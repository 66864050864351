<template>
  <div class="cartBox" :key="listItems.checked">
      <div class="listBox" v-if="listItems.state == 1">
      <div class="cartList">
        <div class="cartListChoice" @click="setCheckedType(listItems.nbr)">
          <!--  @click="setCheckedType(listItems.nbr)" -->
          <img
            v-if="listItems.checked == true"
            src="../assets/img/redGroup.png"
            alt=""
          />
          <img v-else src="../assets/img/rectangle.png" alt="" />
        </div>
        <div class="cartTitle">
          <img class="cartImg" :src="listItems.commodity_first_pic" alt="" />
          <span class="cartCommName">{{ listItems.commodity_name }}</span>
        </div>
        <div class="cartStatistics">
          <div class="cartStock">库存：{{ listItems.quantity }}</div>
          <div class="cartVolume">销量：{{ listItems.sales_num }}</div>
        </div>
        <div class="cartSection">
          <div class="cartMultiple">￥{{ listItems.min_sell_price }}</div>
        </div>
        <div class="cartDel">
          <a-button type="dashed" class="cartEliminate" @click="showDeleteConfirm('0',listItems.nbr,index)">删除</a-button>
        </div>
      </div>
    </div>

    <div class="listBoxInvalid"  v-if="listItems.state != 1">
      <div class="cartListInvalid">
        <div class="cartListChoiceInvalid">失效</div>
        <div class="cartTitleInvalid">
          <img class="cartImgInvalid" :src="listItems.commodity_first_pic" alt="" />
          <span class="cartCommNameInvalid">{{ listItems.commodity_name }}</span>
        </div>
        <div class="cartStatisticsInvalid">
          <div class="cartStockInvalid">库存：{{ listItems.quantity }}</div>
          <div class="cartVolumeInvalid">销量：{{ listItems.sales_num }}</div>
        </div>
        <div class="cartSectionInvalid">
          <div class="cartMultipleInvalid">￥{{ listItems.min_sell_price }}</div>
        </div>
        <div class="cartDelInvalid">
           <a-button type="dashed" class="cartEliminate" @click="showDeleteConfirm('2',listItems.nbr,index)">删除</a-button>
          <!-- <div class="cartBtnInvalid" @click="reqEliminates('2',listItems.key,index)">删除</div> -->
        </div>
      </div>
    </div>
  </div>

</template>
<script>
export default {
  name: "Cart",
  components: {},
  props: {
    listItem: Object,
    index:Number,
    setCheckedType: {
      type: Function,
      default: null,
    },
    reqEliminates: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      listItems: {},
      info: "",
    };
  },
  watch: {
    
  },
  methods: {
    // 删除确认弹窗
    showDeleteConfirm(type, nbr, index) {
      let that = this;
      this.$confirm({
        title: "确认删除商品嘛?",
        okText: "确认",
        okType: "danger",
        cancelText: "取消",
        onOk() {
          if(type == 1){
            that.reqEliminates(type, nbr, index);
          }else{
            that.reqEliminates(type, nbr, index);
          }
        },
        onCancel() {},
      });
    },

    toUpdateList(){
      this.listItems = this.listItem;
    }
  },
  mounted() {},
  created() {
    this.toUpdateList();
  },
};
</script>
<style lang="less" scoped>
.cartBox {
  width: 1190px;
  margin: 0 auto;
  background: #ffffff;
}

.listBox {
  width: 100%;
  padding: 10px 0px 5px 0;
  .cartList {
    width: 1152px;
    background: #f3f3f3;
    border-radius: 10px;
    margin: 0px 18px 5px 18px;
    display: flex;
    .cartListChoice {
      width: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .cartTitle {
      width: 480px;
      display: flex;
      padding: 24px 0 24px 0;
      .cartImg {
        width: 102px;
        height: 102px;
        border-radius: 6px;
      }
      .cartCommName {
        font-size: 16px;
        color: #333333;
        margin-left: 14px;
      }
    }
    .cartStatistics {
      width: 304px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .cartStock {
        font-size: 16px;
        color: #3a3a3a;
      }
      .cartVolume {
        font-size: 16px;
        color: #3a3a3a;
      }
    }
    .cartSection {
      width: 136px;
      display: flex;
      justify-content: center;
      align-items: center;
      .cartMultiple {
        font-size: 16px;
        color: #ff1500;
      }
    }
    .cartDel {
      width: 176px;
      display: flex;
      justify-content: center;
      align-items: center;
      .cartBtn {
        font-size: 16px;
        color: #1890ff;
      }
    }
  }
}
.listBoxInvalid{
  width: 100%;
  padding: 5px 0 5px 0;
  .cartListInvalid {
    width: 1152px;
    background: #f3f3f3;
    border-radius: 10px;
    margin: 0px 18px 5px 18px;
    display: flex;
    .cartListChoiceInvalid {
      width: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .cartTitleInvalid {
      width: 480px;
      display: flex;
      padding: 24px 0 24px 0;
      .cartImgInvalid {
        width: 102px;
        height: 102px;
      }
      .cartCommNameInvalid {
        font-size: 16px;
        color: #CFCFCF;
        margin-left: 14px;
      }
    }
    .cartStatisticsInvalid {
      width: 304px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .cartStockInvalid {
        font-size: 16px;
        color: #CFCFCF;
      }
      .cartVolumeInvalid {
        font-size: 16px;
        color: #CFCFCF;
      }
    }
    .cartSectionInvalid {
      width: 136px;
      display: flex;
      justify-content: center;
      align-items: center;
      .cartMultipleInvalid {
        font-size: 16px;
        color: #CFCFCF;
      }
    }
    .cartDelInvalid {
      width: 176px;
      display: flex;
      justify-content: center;
      align-items: center;
      .cartBtnInvalid {
        font-size: 16px;
        color: #1890ff;
      }
    }
  }
}
</style>
<style scoped>
</style>